const coinListRaw = {
  "42": "42 Coin",
  "300": "300 token",
  "365": "365Coin",
  "404": "404Coin",
  "433": "433 Token",
  "611": "SixEleven",
  "808": "808",
  "888": "Octocoin",
  "1337": "EliteCoin",
  "2015": "2015 coin",
  "ARC*": "Arcade City",
  "CLUB": "ClubCoin",
  "007": "007 coin",
  "ZCN": "0chain",
  "ZRX": "0x",
  "0xBTC": "0xBitcoin",
  "BIT16": "16BitCoin",
  "MCT": "1717 Masonic Commemorative Token",
  "1CR": "1Credit",
  "1WO": "1World",
  "CHAO": "23 Skidoo",
  "ARMS": "2Acoin",
  "2BACCO": "2BACCO Coin",
  "2GIVE": "2GiveCoin",
  "2TF": "2TF",
  "32BIT": "32Bitcoin",
  "3XD": "3DChain",
  "3DES": "3DES",
  "8BT": "8 Circuit Studios",
  "8BIT": "8BIT Coin",
  "ATKN": "A-Token",
  "AAA": "AAA Reserve Currency",
  "RTB": "AB-CHAIN",
  "ABC": "AB-Chain",
  "AT": "ABCC Token",
  "AC3": "AC3",
  "ACA": "ACA Token",
  "ACT": "ACT",
  "ACOIN": "ACoin",
  "AENT": "AEN",
  "AEON": "AEON",
  "AERGO": "AERGO",
  "AGT": "AGATE",
  "AIC": "AI Crypto",
  "AIDOC": "AI Doctor",
  "AIT": "AIChain Token",
  "XAI*": "AICoin",
  "AIOT": "AIOT Token",
  "AITT": "AITrading",
  "AXT": "AIX",
  "ALX": "ALAX",
  "ALIS": "ALISmedia",
  "ALT": "ALTcoin",
  "AMBT": "AMBT Token",
  "AMIS": "AMIS",
  "AMLT": "AMLT",
  "AMO": "AMO Coin",
  "ANON": "ANON",
  "ANTS": "ANTS Reloaded",
  "APIS": "APIS",
  "APS": "APRES",
  "QEY": "AQwire",
  "ARB*": "ARBITRAGE",
  "ARE": "ARENON",
  "ARK": "ARK",
  "ARNA": "ARNA Panacea",
  "ARR": "ARROUND",
  "ASGC": "ASG",
  "ASQT": "ASQ Protocol",
  "ATB": "ATB coin",
  "ATCC": "ATC Coin",
  "ATFS": "ATFS Project",
  "ATL": "ATLANT",
  "ATM": "ATMChain",
  "AUC*": "AU-Coin",
  "AWAX": "AWAX",
  "AXR": "AXRON",
  "AXS": "AXS",
  "ABELE": "Abele",
  "ABJ": "Abjcoin",
  "ABS": "Absolute Coin",
  "ACC*": "Accelerator Network",
  "ACCO": "Accolade",
  "AEC": "AcesCoin",
  "ACES": "AcesCoin",
  "ACT*": "Achain",
  "ACH": "AchieveCoin",
  "ACID": "AcidCoin",
  "OAK": "Acorn Collective",
  "ACM": "Actinium",
  "ACTN": "Action Coin",
  "AMT": "Acumen",
  "AAC": "Acute Angle Cloud",
  "ACC": "AdCoin",
  "ADX": "AdEx",
  "ADT": "AdToken",
  "ADAB": "Adab Solutions",
  "ADM": "Adamant",
  "ADB": "Adbank",
  "ADL": "Adelphoi",
  "ADH": "Adhive",
  "ADI": "Aditus",
  "ADST": "Adshares",
  "AIB": "AdvancedInternetBlock",
  "ADZ": "Adzcoin",
  "AGS": "Aegis",
  "AEN": "Aenco",
  "AERM": "Aerium",
  "AERO": "Aero Coin",
  "AM": "AeroMe",
  "ARN": "Aeron",
  "XRM": "Aerum",
  "AER": "Aeryus",
  "AE": "Aeternity",
  "ATT": "Aeternum",
  "AET": "AfterEther",
  "AGVC": "AgaveCoin",
  "ESTATE": "AgentMile",
  "AGRS": "Agoras Token",
  "DLT": "Agrello Delta",
  "AHT": "Ahoolee",
  "AIBB": "AiBB",
  "AID": "AidCoin",
  "ADN": "Aiden",
  "ADK": "Aidos Kuneen",
  "AIX": "Aigang",
  "AIM": "Aimedis",
  "AION": "Aion",
  "APOD": "AirPod",
  "AST": "AirSwap",
  "AIR": "AirToken",
  "AIRT": "Aircraft",
  "AKA": "Akroma",
  "ALCE": "Alcedo",
  "ALEX": "Alexandrite",
  "PLM": "Algo.Land",
  "ALG": "Algory",
  "ABBC": "Alibabacoin",
  "ALN": "AlienCoin",
  "SOC": "All Sports Coin",
  "AFO": "AllForOneBusiness",
  "ASAFE2": "Allsafe",
  "APC": "AlpaCoin",
  "ALPS": "Alpenschillling",
  "ALF": "AlphaCoin",
  "AX": "AlphaX",
  "ACAT": "Alphacat",
  "ALP": "Alphacon",
  "APZ": "Alprockz",
  "ALQO": "Alqo",
  "ALTCOM": "AltCommunity Coin",
  "ALTOCAR": "AltoCar",
  "ALUX": "Alux Bank",
  "AS": "AmaStar",
  "AMBER": "AmberCoin",
  "AMB": "Ambrosus",
  "AMC": "AmericanCoin",
  "AMX": "Amero",
  "AMMO": "Ammo Rewards",
  "AMN": "Amon",
  "AMOS": "Amos",
  "VEO": "Amoveo",
  "AMS": "Amsterdam Coin",
  "AMY": "Amygws",
  "ANCP": "Anacrypt",
  "ANAL": "AnalCoin",
  "ACP": "Anarchists Prime",
  "AND": "AndromedaCoin",
  "ANGL": "Angel Token",
  "AVH": "Animation Vision Cash",
  "ANI": "Animecoin",
  "ANK": "Ankorus Token",
  "ANKR": "Ankr Network",
  "ANC": "Anoncoin",
  "RYZ": "Anryze",
  "ANTI": "Anti Bitcoin",
  "ATHK": "AntiHACK.me",
  "ANTC": "AntiLitecoin",
  "CPX": "Apex Token",
  "APEX": "ApexCoin",
  "APH": "Aphelion",
  "APL": "Apollo Currency",
  "APXT": "ApolloX",
  "XAP": "Apollon",
  "APPC": "AppCoins",
  "APT": "Aptcoin",
  "APX": "Apx",
  "AQUA": "Aquachain",
  "ARCO": "AquariusCoin",
  "AR*": "Ar.cash",
  "ARQ": "ArQmA",
  "ALC": "Arab League Coin",
  "ANT": "Aragon",
  "ARAW": "Araw",
  "ARBI": "Arbi",
  "ARB": "Arbit Coin",
  "ARCT": "ArbitrageCT",
  "ABT": "ArcBlock",
  "ARCX": "ArcadierX",
  "ARCH": "ArchCoin",
  "ARC": "ArcticCoin",
  "ARDR": "Ardor",
  "ARENA": "Arena",
  "ARG": "Argentum",
  "AGM": "Argoneum",
  "ARGUS": "ArgusCoin",
  "ARI": "AriCoin",
  "ARO": "Arionum",
  "BOTS": "ArkDAO",
  "ARM": "Armory Coin",
  "ARPA": "ArpaCoin",
  "ABY": "ArtByte",
  "ATP": "ArtPro",
  "ARS": "Artcoin",
  "ARTE": "Artemine",
  "ATX": "ArtexCoin",
  "AUA": "ArubaCoin",
  "ASN": "Ascension Coin",
  "XAS": "Asch",
  "AC": "Asia Coin",
  "ADCN": "Asiadigicoin",
  "ATX*": "Aston",
  "AST*": "Astral",
  "ASTRO": "Astronaut",
  "ATH": "Atheios",
  "THO": "Athero",
  "ATMOS": "Atmos",
  "ATOM": "Atomic Coin",
  "AWC": "Atomic Wallet Coin",
  "ATMI": "Atonomi",
  "ATTR": "Attrace",
  "AUC": "Auctus",
  "ADC": "AudioCoin",
  "REP": "Augur",
  "AUK": "Aukcecoin",
  "AURS": "Aureus",
  "AURA": "Aurora",
  "AOA": "Aurora",
  "AUR": "Aurora Coin",
  "AURUM": "Aurum",
  "AUD": "Aussie Digital",
  "AUN": "Authoreon",
  "ATS": "Authorship",
  "AUPC": "Authpaper",
  "ABX": "AutoBay",
  "ATC": "AutoBlock",
  "NIO*": "Autonio",
  "AUT": "Autoria",
  "ATM*": "Autumncoin",
  "Auxilium": "Auxilium",
  "AVA": "Avalon",
  "AV": "Avatar Coin",
  "AVT": "AventCoin",
  "AOP": "Averopay",
  "AVE": "Avesta",
  "ACN": "AvonCoin",
  "WORK": "Aworker",
  "AXIOM": "Axiom Coin",
  "AXYS": "Axys",
  "AZART": "Azart",
  "AZ": "Azbit",
  "AZU": "Azultec",
  "B21": "B21",
  "B2B": "B2BX",
  "B3": "B3 Coin",
  "KB3": "B3Coin",
  "BAX": "BABB",
  "BAM": "BAM",
  "BANCA": "BANCA",
  "BKX": "BANKEX",
  "BBN": "BBNCOIN",
  "BCV": "BCV Blue Chip",
  "BEAT": "BEAT Token",
  "BEER": "BEER Coin",
  "BERN": "BERNcash",
  "BEX": "BEX token",
  "BFT": "BF Token",
  "BFEX": "BFEX",
  "BHPC": "BHPCash",
  "BITTO": "BITTO",
  "VEE": "BLOCKv",
  "BMT": "BMChain",
  "BSC*": "BOONSCoin",
  "BST*": "BOOSTO",
  "BOS": "BOScoin",
  "BQC": "BQCoin",
  "BQTX": "BQT",
  "BVO": "BRAVO Pay",
  "BRAT": "BROTHER",
  "BTCL": "BTC Lite",
  "BTCM": "BTCMoon",
  "BTU": "BTU Protocol",
  "BAAS": "BaaSid",
  "BAN": "Babes and Nerds",
  "BKC": "Balkancoin",
  "NANAS": "BananaBits",
  "BNT": "Bancor Network Token",
  "XBANK": "Bancryp",
  "B@": "BankCoin",
  "BNK": "Bankera",
  "BCOIN": "BannerCoin",
  "BBN*": "Banyan Network",
  "BBCC": "BaseballCardCoin",
  "BASHC": "BashCoin",
  "BAT": "Basic Attention Token",
  "BASIS": "Basis",
  "BTA": "Bata",
  "BCX": "BattleCoin",
  "BSTK": "BattleStake",
  "SAND": "BeachCoin",
  "BEAM": "Beam",
  "BEAN": "BeanCash",
  "BRDD": "BeardDollars",
  "XBTS": "Beats",
  "BTZC": "BeatzCoin",
  "BEC": "Beauty Chain",
  "BVC": "BeaverCoin",
  "BXY": "Beaxy",
  "BEE": "Bee Token",
  "BFDT": "Befund",
  "BELA": "Bela",
  "BBI": "BelugaPay",
  "BMK": "Benchmark",
  "BNC": "Benjacoin",
  "BENJI": "BenjiRolls",
  "BEST": "BestChain",
  "KNG": "BetKings",
  "CHART": "BetOnChart",
  "BET": "BetaCoin",
  "BFC": "Betform",
  "BETHER": "Bethereum",
  "KRO": "Betoken",
  "BTRM": "Betrium Token",
  "BETR": "BetterBetting",
  "BTXC": "Bettex coin",
  "BETT": "Bettium",
  "BC": "Beverage.cash",
  "BZNT": "Bezant",
  "BEZ": "Bezop",
  "BGG": "Bgogo Token",
  "BNR": "BiNeuro",
  "BBP": "BiblePay",
  "BIX": "BiboxCoin",
  "BID": "BidCoin",
  "BDP": "Bidipass",
  "DOOH": "Bidooh",
  "BDB": "Big Data Block",
  "BBG": "BigBang",
  "HUGE": "BigCoin",
  "LFC": "BigLifeCoin",
  "BIGUP": "BigUp",
  "BBO": "Bigbom",
  "BHC": "BighanCoin",
  "BIC": "Bikercoins",
  "BLRY": "BillaryCoin",
  "XBL": "Billionaire Token",
  "BNB": "Binance Coin",
  "BRC*": "BinaryCoin",
  "BIOB": "BioBar",
  "BIOC": "BioCrypt",
  "BIO": "Biocoin",
  "BIOS": "BiosCrypto",
  "BTRN": "Biotron",
  "BIPC": "BipCoin",
  "BIS": "Bismuth",
  "BZ": "Bit-Z",
  "BAS": "BitAsean",
  "BTB": "BitBar",
  "BAY": "BitBay",
  "BBK": "BitBlocks",
  "BBT": "BitBoost",
  "BOSS": "BitBoss",
  "BRONZ": "BitBronze",
  "BCD*": "BitCAD",
  "BEN": "BitCOEN",
  "BCNA": "BitCanna",
  "BITCAR": "BitCar",
  "CAT*": "BitClave",
  "COAL": "BitCoal",
  "BCCOIN": "BitConnect Coin",
  "BCR": "BitCredit",
  "BTCRY": "BitCrystal",
  "BCY": "BitCrystals",
  "BTCR": "BitCurrency",
  "BDG": "BitDegree",
  "CSNO": "BitDice",
  "HNY": "BitFence",
  "BFX": "BitFinex Tokens",
  "FLIP": "BitFlip",
  "FLX*": "BitFlux",
  "BF": "BitForex Token",
  "HIRE*": "BitHIRE",
  "BIH": "BitHostCoin",
  "STU": "BitJob",
  "BTLC": "BitLuckCoin",
  "LUX*": "BitLux",
  "BTM": "BitMark",
  "BMX": "BitMart Coin",
  "BTMX": "BitMax Token",
  "BTMI": "BitMiles",
  "BITM": "BitMoney",
  "BM": "BitMoon",
  "BITOK": "BitOKX",
  "BTQ": "BitQuark",
  "RNTB": "BitRent",
  "BIT": "BitRewards",
  "BSCH": "BitSchool",
  "BITX": "BitScreener",
  "XSEED": "BitSeeds",
  "BSD": "BitSend",
  "BTE*": "BitSerial",
  "BSR": "BitSoar Coin",
  "BTSG": "BitSong",
  "STASH": "BitStash",
  "BSTN": "BitStation",
  "BST": "BitStone",
  "SWIFT": "BitSwift",
  "BXT": "BitTokens",
  "BTT": "BitTorrent",
  "TUBE": "BitTube",
  "VEG": "BitVegan",
  "VOLT": "BitVolt",
  "BTW": "BitWhite",
  "ZNY": "BitZeny",
  "XBOND": "Bitacium",
  "BTCA": "Bitair",
  "BAC": "BitalphaCoin",
  "BB1": "Bitbond",
  "BOSE": "Bitbose",
  "BTMG": "Bitcademy Football",
  "BTD": "Bitcloud",
  "BTDX": "Bitcloud 2.0",
  "BTCN": "BitcoiNote",
  "B2G": "Bitcoiin2Gen",
  "BTC": "Bitcoin",
  "BCA": "Bitcoin Atom",
  "CDY": "Bitcoin Candy",
  "BCH": "Bitcoin Cash",
  "BTCC": "Bitcoin Core",
  "BCD": "Bitcoin Diamond",
  "BTG": "Bitcoin Gold",
  "BITG": "Bitcoin Green",
  "BTCH": "Bitcoin Hush",
  "XBI": "Bitcoin Incognito",
  "BCI": "Bitcoin Interest",
  "BTN": "Bitcoin Nova",
  "BTPL": "Bitcoin Planet",
  "BTCP": "Bitcoin Private",
  "BTCRED": "Bitcoin Red",
  "BCR*": "Bitcoin Royal",
  "BSV": "Bitcoin SV",
  "BTCS": "Bitcoin Scrypt",
  "BT2": "Bitcoin SegWit2X",
  "BTCS*": "Bitcoin Supreme",
  "BTCD": "BitcoinDark",
  "BTCE*": "BitcoinEX",
  "BCF": "BitcoinFast",
  "BIFI": "BitcoinFile",
  "BTF*": "BitcoinFor",
  "BTCGO": "BitcoinGo",
  "XBC": "BitcoinPlus",
  "BWS": "BitcoinWSpectrum",
  "BCX*": "BitcoinX",
  "BTCZ": "BitcoinZ",
  "BTCUS": "Bitcoinus",
  "BM*": "Bitcomo",
  "BTX": "Bitcore",
  "DARX": "Bitdaric",
  "BDL": "Bitdeal",
  "XBX": "BiteX",
  "BT1": "Bitfinex Bitcoin Future",
  "BTR": "Bither",
  "BTH": "Bithereum",
  "KAN": "Bitkan",
  "BTCL*": "BitluckCoin",
  "BIM": "BitminerCoin",
  "BMXT": "Bitmxittz",
  "XPAT": "Bitnation Pangea",
  "BQ": "Bitqy",
  "BRO": "Bitradio",
  "BTL": "Bitrolium",
  "BITSD": "Bits Digit",
  "BINS": "Bitsense",
  "BTS": "Bitshares",
  "BSX": "Bitspace",
  "XBS": "Bitstake",
  "BITS": "BitstarCoin",
  "BITS*": "Bitswift",
  "BWT": "Bittwatt",
  "BTV": "Bitvote",
  "BWT2": "Bitwin 2.0",
  "BITZ": "Bitz Coin",
  "BTZ": "BitzCoin",
  "BTZN": "Bitzon",
  "BXM": "Bixtrim",
  "BZKY": "Bizkey",
  "XBP": "Black Pearl Coin",
  "BLK": "BlackCoin",
  "BS": "BlackShadowCoin",
  "BBOS": "Blackbox Foundation",
  "BHC*": "BlackholeCoin",
  "BMC": "Blackmoon Crypto",
  "BSTAR": "Blackstar",
  "BBTC": "BlakeBitcoin",
  "BLC": "BlakeCoin",
  "BLAS": "BlakeStar",
  "BLAZR": "BlazerCoin",
  "BLKD": "Blinked",
  "BLITZ": "BlitzCoin",
  "XBP*": "BlitzPredict",
  "ARY": "Block Array",
  "BLTG": "Block-Logic",
  "BCAT": "BlockCAT",
  "BCDN": "BlockCDN",
  "LNC": "BlockLancer",
  "BCPT": "BlockMason Credit Protocol",
  "BMH": "BlockMesh",
  "BLOCK": "BlockNet",
  "BLOCKPAY": "BlockPay",
  "BPL": "BlockPool",
  "BCAP": "Blockchain Capital",
  "BCDT": "Blockchain Certified Data Token",
  "BLX": "Blockchain Index",
  "BCT": "Blockchain Terminal",
  "BTF": "Blockchain Traded Fund",
  "BCIO": "Blockchain.io",
  "BDT": "Blockonix",
  "PASS": "Blockpass",
  "BPT": "Blockport",
  "CCC*": "Blockshipping",
  "TIX": "Blocktix",
  "BKT": "Blocktrade token",
  "BLV": "Blockvest",
  "BNTN": "Blocnation",
  "CYS": "BlooCYS",
  "BLT": "Bloom Token",
  "CDT": "Blox",
  "BLU": "BlueCoin",
  "BDR": "BlueDragon",
  "BLZ": "Bluzelle",
  "BNX": "BnrtxCoin",
  "BOB": "Bob's Repair",
  "BOT": "Bodhi",
  "BOE": "Bodhi",
  "BOG": "Bogcoin",
  "BOLD": "Bold",
  "BLN*": "Bolenum",
  "BOLI": "BolivarCoin",
  "BOLTT": "BolttCoin",
  "BOMB": "BombCoin",
  "BONA": "Bonafi",
  "BON*": "BonesCoin",
  "BON": "Bonpay",
  "BBR": "Boolberry",
  "BOST": "BoostCoin",
  "BMG": "Borneo",
  "BOSON": "BosonCoin",
  "BOS*": "Bostoken",
  "BOTC": "BotChain",
  "CAP": "BottleCaps",
  "BTO": "Bottos",
  "BOU": "Boulle",
  "BNTE": "Bountie",
  "XBTY": "Bounty",
  "BNTY": "Bounty0x",
  "BOUTS": "BoutsPro",
  "AHT*": "Bowhead Health",
  "BSC": "BowsCoin",
  "BOXY": "BoxyCoin",
  "IMPCN": "Brain Space",
  "BRAIN": "BrainCoin",
  "BRAZ": "Brazio",
  "BRD": "Bread token",
  "BRX": "Breakout Stake",
  "BRK": "BreakoutCoin",
  "BRIA": "Briacoin",
  "XBB": "BrickBlock",
  "BCO": "BridgeCoin",
  "BRC": "BrightCoin",
  "BRIK": "BrikBit",
  "BRIT": "BritCoin",
  "BRNX": "Bronix",
  "BXC": "BtcEX",
  "BUBO": "Budbo",
  "BUD": "Buddy",
  "BGL": "Buglab",
  "BT": "BuildTeam",
  "BULLS": "BullshitCoin",
  "BWK": "Bulwark",
  "BUN": "BunnyCoin",
  "BURST": "BurstCoin",
  "BUZZ": "BuzzCoin",
  "BYC": "ByteCent",
  "BTE": "ByteCoin",
  "BCN": "ByteCoin",
  "GBYTE": "Byteball",
  "BYTHER": "Bytether",
  "BTM*": "Bytom",
  "XCT": "C-Bits",
  "C25": "C25 Coin",
  "CAIx": "CAIx",
  "CBD": "CBD Crystals",
  "CCC": "CCCoin",
  "CDRX": "CDRX",
  "CDX": "CDX Network",
  "CEDEX": "CEDEX Coin",
  "CEEK": "CEEK Smart VR Token",
  "CETI": "CETUS Coin",
  "CHIPS": "CHIPS",
  "CIC": "CIChain",
  "CINX": "CINDX",
  "CINNI": "CINNICOIN",
  "CLAM": "CLAMS",
  "CO2": "CO2 Token",
  "CMS": "COMSA",
  "CNCT": "CONNECT",
  "CPY": "COPYTRACK",
  "COSS": "COSS",
  "COTI": "COTI",
  "CPC*": "CPChain",
  "MLS": "CPROP",
  "CPROP": "CPROP",
  "CRWD": "CRWD Network",
  "CMZ": "CRYPTOMAGZ",
  "CRS*": "CRYSTALS",
  "CYBR": "CYBR",
  "CAB": "CabbageUnit",
  "CHE": "Cache",
  "CACH": "Cachecoin",
  "CF": "Californium",
  "CALC": "CaliphCoin",
  "CLO": "Callisto Network",
  "CAM": "Camcoin",
  "CMPCO": "CampusCoin",
  "CAN": "CanYaCoin",
  "CND*": "Canada eCoin",
  "CDN": "Canada eCoin",
  "CAND": "Canlead",
  "CCN": "CannaCoin",
  "XCI": "Cannabis Industry Coin",
  "CANN": "CannabisCoin",
  "CNAB": "Cannabium",
  "XCD": "Capdax",
  "CAPP": "Cappasity",
  "CPC": "CapriCoin",
  "CAR": "CarBlock",
  "CTX": "CarTaxi",
  "CV": "CarVertical",
  "CARAT": "Carats Token",
  "CUSD": "Carbon",
  "CARBON": "Carboncoin",
  "ADA": "Cardano",
  "CARD": "Cardstack",
  "CARE*": "Care Token",
  "CARE": "Carebit",
  "CRGO": "CargoCoin",
  "TICS": "CargoConX",
  "CXO": "CargoX",
  "DIEM": "CarpeDiemCoin",
  "CTC": "CarterCoin",
  "CNBC": "Cash & Back Coin",
  "CASH*": "Cash Poker Pro",
  "CBC*": "CashBagCoin",
  "CBC": "CashBet Coin",
  "CASH": "CashCoin",
  "CSH": "CashOut",
  "CAS": "Cashaa",
  "CSC": "CasinoCoin",
  "CST": "Casper API",
  "CSP": "Caspian",
  "CSTL": "Castle",
  "CAT": "Catcoin",
  "CATT": "Catex",
  "CVTC": "CavatCoin",
  "CAV": "Caviar",
  "CCO": "Ccore",
  "CEL": "Celsius Network",
  "CTR": "Centra",
  "CENNZ": "Centrality Token",
  "CNT": "Centurion",
  "CBS": "Cerberus",
  "XCE": "Cerium",
  "CHC": "ChainCoin",
  "LINK": "ChainLink",
  "ZILLA": "ChainZilla",
  "CHX": "Chainium",
  "CHAN": "ChanCoin",
  "CAG": "Change",
  "CHA": "Charity Coin",
  "CHARM": "Charm Coin",
  "CXC": "CheckCoin",
  "CHK": "Chek",
  "CHESS": "ChessCoin",
  "CHILD": "ChildCoin",
  "CNC": "ChinaCoin",
  "CHIP": "Chip",
  "CHOOF": "ChoofCoin",
  "DAY": "Chronologic",
  "CRX": "ChronosCoin",
  "CHW": "Chrysalis Coin",
  "CLPX": "Chynge.net",
  "CIN": "CinderCoin",
  "CND": "Cindicator",
  "CIR": "CircuitCoin",
  "COVAL": "Circuits of Value",
  "CTL": "Citadel",
  "CTW": "Citowise",
  "CVC": "Civic",
  "CLRTY": "Clarity",
  "CAID": "ClearAid",
  "XCLR": "ClearCoin",
  "POLL": "ClearPoll",
  "CLV": "CleverCoin",
  "CHASH": "CleverHash",
  "CLICK": "Clickcoin",
  "CLIN": "Clinicoin",
  "CLINT": "Clinton",
  "CCCX": "Clipper Coin Capital",
  "CLOAK": "CloakCoin",
  "CKC": "Clockcoin",
  "CLD": "Cloud",
  "CLOUT": "Clout",
  "CLDX": "Cloverdex",
  "CLUD": "CludCoin",
  "COE": "CoEval",
  "COT": "CoTrader",
  "COVEX": "CoVEX",
  "COB": "Cobinhood",
  "COX": "CobraCoin",
  "CTT": "CodeTract",
  "CFC": "CoffeeCoin",
  "CFI": "Cofound.it",
  "COGEN": "Cogenero",
  "COGS": "Cogmento",
  "COG": "Cognitio",
  "COIN*": "Coin",
  "COY": "Coin Analyst",
  "XMG": "Coin Magi",
  "BTTF": "Coin to the Future",
  "C2": "Coin.2",
  "CONI": "CoinBene",
  "CET": "CoinEx token",
  "COFI": "CoinFi",
  "IMP": "CoinIMP",
  "XCJ": "CoinJob",
  "CL": "CoinLancer",
  "LION": "CoinLion",
  "MEE": "CoinMeet",
  "MEET": "CoinMeet",
  "XCM": "CoinMetro",
  "CPL": "CoinPlace Token",
  "CHP": "CoinPoker Token",
  "CPEX": "CoinPulseToken",
  "CCH": "Coinchase",
  "DAILY": "Coindaily",
  "CGT": "Coingrid",
  "CTIC": "Coinmatic",
  "COI": "Coinnec",
  "CNO": "Coino",
  "CNMT": "Coinomat",
  "CXT": "Coinonat",
  "XCXT": "CoinonatX",
  "TCJ": "Coinshare",
  "CEN": "Coinsuper Ecosystem Network",
  "COIN": "Coinvest",
  "CWT": "Coinware",
  "CXG": "Coinxes",
  "COLX": "ColossusCoinXT",
  "CLN": "Colu Local Network",
  "CBP": "ComBox",
  "CMTC": "CometCoin",
  "CBT": "CommerceBlock Token",
  "CMM": "Commercium",
  "COMM": "Community Coin",
  "COC": "Community Coin",
  "CMP": "Compcoin",
  "COMP": "Compound Coin",
  "CPN": "CompuCoin",
  "CYC": "ConSpiracy Coin",
  "CNL": "ConcealCoin",
  "RAIN": "Condensate",
  "CFD": "Confido",
  "CJR": "Conjure",
  "CJT": "ConnectJob Token",
  "CTY": "Connecty",
  "CQST": "ConquestCoin",
  "SEN*": "Consensus",
  "DAG": "Constellation",
  "CNN": "Content Neutrality Network",
  "CAN*": "Content and AD Network",
  "BOX": "ContentBox",
  "COS": "Contentos",
  "CUZ": "Cool Cousin",
  "COOL": "CoolCoin",
  "CCX": "CoolDarkCoin",
  "XCPO": "Copico",
  "CLR": "CopperLark",
  "CORAL": "CoralPay",
  "CORE": "Core Group Asset",
  "COR": "Corion",
  "CTXC": "Cortex",
  "COSX": "Cosmecoin",
  "CSMIC": "Cosmic",
  "CMOS": "Cosmo",
  "COSM": "CosmoChain",
  "ATOM*": "Cosmos",
  "CMC": "CosmosCoin",
  "COU": "Couchain",
  "XCP": "CounterParty",
  "CHT": "Countinghouse Fund",
  "COV*": "CovenCoin",
  "COV": "Covesting",
  "CPLO": "Cpollo",
  "CRAB": "CrabCoin",
  "CRACK": "CrackCoin",
  "CRAFT": "Craftcoin",
  "CFTY": "Crafty",
  "CRAIG": "CraigsCoin",
  "CRNK": "CrankCoin",
  "CRAVE*": "Crave-NG",
  "CRAVE": "CraveCoin",
  "CRV": "Cravy",
  "CZC": "Crazy Coin",
  "CRM": "Cream",
  "XCRE": "Creatio",
  "CREA": "CreativeChain",
  "CRDNC": "Credence Coin",
  "PESA": "Credible",
  "CREDIT": "Credit",
  "CRB": "Creditbit",
  "CRE*": "Creditcoin",
  "CCOIN": "Creditcoin",
  "CDPT": "Creditor Data Platform",
  "CRE": "Credits",
  "CRDS": "Credits",
  "CS*": "Credits",
  "CFT*": "Credo",
  "CREDO": "Credo",
  "CREVA": "Creva Coin",
  "CROAT": "Croat",
  "CMCT": "Crowd Machine",
  "CRC***": "CrowdCoin",
  "CCOS": "CrowdCoinage",
  "CRF": "CrowdForce",
  "CDP": "CrowdPrecision",
  "YUP": "Crowdholding",
  "WIZ": "Crowdwiz",
  "CRW": "Crown Coin",
  "CRC**": "CryCash",
  "IPT": "Crypt-ON",
  "CRYPT": "CryptCoin",
  "CPT": "Cryptaur",
  "CRL": "Cryptelo Coin",
  "CRPT": "Crypterium",
  "XCR": "Crypti",
  "CRYP": "CrypticCoin",
  "QRP": "Cryptics",
  "CTO": "Crypto",
  "ANGEL": "Crypto Angel",
  "CESC": "Crypto Escudo",
  "CIF": "Crypto Improvement Fund",
  "CSPN": "Crypto Sports",
  "TKT": "Crypto Tickets",
  "CWEX": "Crypto Wine Exchange",
  "CWIS": "Crypto Wisdom Coin",
  "CWX": "Crypto-X",
  "MCO": "Crypto.com",
  "CRO": "Crypto.com Chain Token",
  "C20": "Crypto20",
  "CABS": "CryptoABS",
  "BUK": "CryptoBuk",
  "CBX": "CryptoBullion",
  "CCRB": "CryptoCarbon",
  "CIRC": "CryptoCircuits",
  "FCS": "CryptoFocus",
  "CFT": "CryptoForecast",
  "CHBR": "CryptoHub",
  "TKR": "CryptoInsight",
  "CJ": "CryptoJacks",
  "CJC": "CryptoJournal",
  "LEU": "CryptoLEU",
  "CPAY": "CryptoPay",
  "CRPS": "CryptoPennies",
  "PING": "CryptoPing",
  "CP": "CryptoProfile",
  "CREV": "CryptoRevolution",
  "CR": "CryptoRiyal",
  "CS": "CryptoSpots",
  "CWV": "CryptoWave",
  "CWXT": "CryptoWorldXToken",
  "CRON": "Cryptocean",
  "CCIN": "Cryptocoin Insurance",
  "CDX*": "Cryptodex",
  "CGA": "Cryptographic Anomaly",
  "CIX100": "Cryptoindex",
  "CYT": "Cryptokenz",
  "CIX": "Cryptonetix",
  "CNX": "Cryptonex",
  "XCN": "Cryptonite",
  "CEFS": "CryptopiaFeeShares",
  "CRS": "Cryptoreal",
  "CXA": "CryptovationX",
  "OXY2": "Cryptoxygen",
  "MN": "Cryptsy Mining Contract",
  "POINTS": "Cryptsy Points",
  "CRTM": "Cryptum",
  "CVCOIN": "Crypviser",
  "CCT": "Crystal Clear Token",
  "AUTO": "Cube",
  "QBT": "Cubits",
  "CUR": "Cura Network",
  "CTKN": "Curaizon",
  "CURE": "Curecoin",
  "CRU": "Curium",
  "CBUK": "CurveBlock",
  "CHFT": "CustomCoin",
  "CCL": "CyClean",
  "XCS": "CybCSec Coin",
  "CCI": "Cyber Capital Invest",
  "CC": "CyberCoin",
  "CMT": "CyberMiles",
  "CABS*": "CyberTrust",
  "CVT": "CyberVein",
  "CRE**": "Cybereits Token",
  "CYDER": "Cyder Coin",
  "CYG": "Cygnus",
  "CYP": "CypherPunkCoin",
  "FUNK": "Cypherfunks Coin",
  "CYRS": "Cyrus Token",
  "DACH": "DACH Coin",
  "DAC": "DACash",
  "DADI": "DADI",
  "DAX": "DAEX",
  "BET*": "DAO.casino",
  "GEN*": "DAOstack",
  "DAPS": "DAPS Token",
  "DAS": "DAS",
  "DATX": "DATx",
  "DAV*": "DAV",
  "DRP": "DCORP",
  "DEEX": "DEEX",
  "CET*": "DICE Money",
  "DIM": "DIMCOIN",
  "DIW": "DIWtoken",
  "DMT": "DMarket",
  "DNTX": "DNAtix",
  "DNN": "DNN Token",
  "MTC": "DOCADEMIC",
  "DOV": "DOVU",
  "DREAM*": "DREAM",
  "DRPU": "DRP Utility",
  "DRACO": "DT Token",
  "DYNO": "DYNO",
  "DAI": "Dai",
  "DAN": "Daneel",
  "DXC*": "Daox",
  "DAR": "Darcrus",
  "PROD": "Darenta",
  "DEC": "Darico",
  "DARK": "Dark",
  "DISK": "Dark Lisk",
  "MOOND": "Dark Moon",
  "DB": "DarkBit",
  "DRKC": "DarkCash",
  "DCC": "DarkCrave",
  "DETH": "DarkEther",
  "DGDC": "DarkGold",
  "DKC": "DarkKnightCoin",
  "DANK": "DarkKush",
  "DSB": "DarkShibe",
  "DT": "DarkToken",
  "DRKT": "DarkTron",
  "DNET": "Darknet",
  "DASC": "DasCoin",
  "DASH": "Dash",
  "DSH": "Dashcoin",
  "DTA": "Data",
  "DTT*": "Data Trading",
  "DTX": "DataBroker DAO",
  "DXT": "DataWallet",
  "DTB": "Databits",
  "DBCCOIN": "Datablockchain",
  "DTC*": "Datacoin",
  "XDT": "Dataeum",
  "DTN": "Datareum",
  "DTRC": "Datarius",
  "DAT": "Datum",
  "DAV": "DavorCoin",
  "DAXX": "DaxxCoin",
  "DTC": "DayTrader Coin",
  "DAYTA": "Dayta",
  "DHT": "DeHedge Token",
  "DNET*": "DeNet",
  "XNA": "DeOxyRibose",
  "DBC*": "Debit Coin",
  "DBTC": "DebitCoin",
  "DEB": "Debitum Token",
  "DCT": "Decent",
  "DBET": "Decent.bet",
  "MANA": "Decentraland",
  "DACC": "Decentralized Accessible Content Chain",
  "DML": "Decentralized Machine Learning",
  "DUBI": "Decentralized Universal Basic Income",
  "HST": "Decision Token",
  "DCR": "Decred",
  "DEEP": "Deep Gold",
  "DBC": "DeepBrain Chain",
  "ONION": "DeepOnion",
  "DEA": "Degas Coin",
  "DEI": "Deimos",
  "DKD": "Dekado",
  "DEL": "DelChain",
  "DPAY": "DelightPay",
  "DPT": "Deliverers Power Token",
  "DPY": "Delphy",
  "DLXV": "Delta-X",
  "DCRE": "DeltaCredits",
  "DNR": "Denarius",
  "DNO": "Denaro",
  "DENT": "Dent",
  "DCN": "Dentacoin",
  "DFBT": "DentalFix",
  "DEPO": "Depository Network",
  "DERO": "Dero",
  "DESI": "Desico",
  "DSR": "Desire",
  "DES": "Destiny",
  "DTCT": "DetectorToken",
  "DTH": "Dether",
  "DVC": "DevCoin",
  "EVE": "Devery",
  "DEV": "Deviant Coin",
  "DXG": "DexAge",
  "DMD": "Diamond",
  "DCK": "DickCoin",
  "DIGS": "Diggits",
  "DGB": "DigiByte",
  "DGC": "DigiCoin",
  "CUBE": "DigiCube",
  "DEUR": "DigiEuro",
  "DIGIF": "DigiFel",
  "DFXT": "DigiFinexToken",
  "DGM": "DigiMoney",
  "DGPT": "DigiPulse",
  "DGMS": "Digigems",
  "DAGT": "Digital Asset Guarantee Token",
  "DPP": "Digital Assets Power Play",
  "DBG": "Digital Bullion Gold",
  "DDF": "Digital Developers Fund",
  "DFS": "Digital Fantasy Sports",
  "TMTG": "Digital Gold Exchange",
  "DRS": "Digital Rupees",
  "XDN": "DigitalNote",
  "DP": "DigitalPrice",
  "DGTX": "Digitex Futures",
  "WAGE": "Digiwage",
  "DGD": "Digix DAO",
  "DGX": "Digix Gold token",
  "DIG": "Dignity",
  "DIME": "DimeCoin",
  "DCY": "Dinastycoin",
  "DIN": "Dinero",
  "XDQ": "Dirac Coin",
  "DIS": "DiscoveryIoT",
  "DCC*": "Distributed Credit Chain",
  "DIT": "Ditcoin",
  "DIVX": "Divi Exchange Token",
  "DIVI": "Divi Project",
  "DTC**": "DivotyCoin",
  "DXC": "DixiCoin",
  "DLISK": "Dlisk",
  "NOTE": "Dnotes",
  "DOC": "Doc Coin",
  "NRN": "Doc.ai Neuron",
  "DOCK": "Dock.io",
  "DOGED": "DogeCoinDark",
  "DGORE": "DogeGoreCoin",
  "XDP": "DogeParty",
  "DOGE": "Dogecoin",
  "DLA": "Dolla",
  "DT1": "Dollar Token 1",
  "DLC": "DollarCoin",
  "DLR": "DollarOnline",
  "DRT": "DomRaider",
  "DON": "DonationCoin",
  "DDL": "Donocle",
  "DOPE": "DopeCoin",
  "DOR": "Dorado",
  "DOT": "Dotcoin",
  "BOAT": "Doubloon",
  "Dow": "DowCoin",
  "DRA": "DraculaCoin",
  "DFT": "Draftcoin",
  "DRG": "Dragon Coin",
  "XDB": "DragonSphere",
  "DRGN": "Dragonchain",
  "DRM8": "Dream8Coin",
  "DREAM": "DreamTeam Token",
  "DRF": "Drife",
  "DRZ": "Droidz",
  "DRC": "Dropcoin",
  "DROP": "Dropil",
  "DRXNE": "Droxne",
  "DUB": "DubCoin",
  "DBIC": "DubaiCoin",
  "DBIX": "DubaiCoin",
  "DUCK": "DuckDuckCoin",
  "DUSK": "Dusk Network",
  "DUTCH": "Dutch Coin",
  "DUX": "DuxCoin",
  "DYC": "Dycoin",
  "DYN": "Dynamic",
  "DTR": "Dynamic Trading Rights",
  "DYNCOIN": "Dyncoin",
  "DTEM": "Dystem",
  "DBR": "Düber",
  "ECC*": "E-CurrencyCoin",
  "EDR": "E-Dinar Coin",
  "EFL": "E-Gulden",
  "EB3": "EB3coin",
  "EBC": "EBCoin",
  "ECC": "ECC",
  "OMI": "ECOMI",
  "ECO": "ECOcoin",
  "EDRC": "EDRCoin",
  "EGO": "EGOcoin",
  "EJAC": "EJA Coin",
  "ELTCOIN": "ELTCOIN",
  "EMANATE": "EMANATE",
  "EMX": "EMX",
  "ET": "ENDO",
  "ENTRC": "ENTER COIN",
  "ENTRY": "ENTRY",
  "EOT*": "EON",
  "EOS": "EOS",
  "EPIK": "EPIK Token",
  "EQL": "EQUAL",
  "EQ": "EQUI",
  "EQUI": "EQUI Token",
  "ERB": "ERBCoin",
  "EST": "ESports Chain",
  "XBASE": "ETERBASE",
  "ETS": "ETH Share",
  "ERA": "ETHA",
  "ETHO": "ETHER-1",
  "EGAS": "ETHGAS",
  "EUNO": "EUNO",
  "EVOS": "EVOS",
  "EXMR": "EXMR",
  "EXRN": "EXRNchain",
  "ETE": "EXTRADECOIN",
  "EYE": "EYE Token",
  "EZX": "EZ Exchange",
  "EZC": "EZCoin",
  "EZM": "EZMarket",
  "EZT": "EZToken",
  "EA": "EagleCoin",
  "EAGS": "EagsCoin",
  "EARTH": "Earth Token",
  "EAC": "EarthCoin",
  "EGDC": "EasyGuide",
  "EMT": "EasyMine",
  "ETKN": "EasyToken",
  "EBZ": "Ebitz",
  "EBS": "EbolaShare",
  "EKO": "EchoLink",
  "EC": "Eclipse",
  "ECOB": "EcoBit",
  "ECR": "EcoVerse",
  "EDDIE": "Eddie coin",
  "EDN": "EdenChain",
  "EDGE": "EdgeCoin",
  "EDG": "Edgeless",
  "EDU": "EduCoin",
  "LEDU": "Education Ecosystem",
  "EDC": "EducoinV",
  "EGG": "EggCoin",
  "EGT": "Egretia",
  "EDO": "Eidoo",
  "EMC2": "Einsteinium",
  "ELC": "Elacoin",
  "XEL": "Elastic",
  "ELA": "Elastos",
  "ECA": "Electra",
  "ELEC": "Electrify.Asia",
  "ELT": "Electron",
  "ETN": "Electroneum",
  "EKN": "Elektron",
  "ELE": "Elementrem",
  "ELM": "Elements",
  "ELES": "Elements Estates",
  "ELI*": "Elicoin",
  "ELI": "Eligma",
  "ELIX": "Elixir",
  "ELLA": "Ellaism",
  "ELP": "Ellerium",
  "ELLI": "ElliotCoin",
  "ELY": "Elysian",
  "ELS": "Elysium",
  "AEC*": "EmaratCoin",
  "EMB": "EmberCoin",
  "MBRS": "Embers",
  "EMD": "Emerald",
  "EMC": "Emercoin",
  "EMN": "Eminent Token",
  "EMIGR": "EmiratesGoldCoin",
  "EPY*": "Emphy",
  "PLEO": "Empleos",
  "EMPC": "EmporiumCoin",
  "EPY": "Empyrean",
  "ENCX": "Encrybit",
  "DNA": "EncrypGen",
  "ETT": "EncryptoTel",
  "ENCN": "EndChain",
  "EDR*": "Endor Protocol Token",
  "ENE": "EneCoin",
  "ENQ": "Enecuum",
  "NRG": "Energi",
  "ETK": "Energi Token",
  "TSL": "Energo",
  "ENRG": "EnergyCoin",
  "ENGT": "Engagement Token",
  "EGCC": "Engine",
  "XNG": "Enigma",
  "ENG": "Enigma",
  "ENJ": "Enjin Coin",
  "ENK": "Enkidu",
  "ENTER": "EnterCoin (ENTER)",
  "ENTRP": "Entropy Token",
  "ENU": "Enumivo",
  "ENV": "Envienta",
  "EVN": "Envion",
  "NVOY": "Envoy",
  "ZYM": "Enzym",
  "EQUAL": "EqualCoin",
  "EQT": "EquiTrader",
  "EQB": "Equibit",
  "EQM": "Equilibrium Coin",
  "EQY": "Eqwity",
  "ERE": "Erecoin",
  "EFYT": "Ergo",
  "ERT*": "Eristica",
  "ERO": "Eroscoin",
  "ERR": "ErrorCoin",
  "ERY": "Eryllium",
  "ESP": "Espers",
  "ERT": "Esports.com",
  "ESS": "Essentia",
  "ETALON": "Etalon",
  "XEC": "Eternal Coin",
  "XET": "Eternal Token",
  "ETT*": "Eternal Trusts",
  "ENT": "Eternity",
  "EBET": "EthBet",
  "ETBS": "EthBits",
  "LEND": "EthLend",
  "HEAL": "Etheal",
  "ETHB": "EtherBTC",
  "EDT": "EtherDelta",
  "DOGETH": "EtherDoge",
  "ETI": "EtherInc",
  "ETL": "EtherLite",
  "ESZ": "EtherSportz",
  "ETZ": "EtherZero",
  "ECH": "EthereCash",
  "ETH": "Ethereum",
  "ETBT": "Ethereum Black",
  "BLUE": "Ethereum Blue",
  "ECASH": "Ethereum Cash",
  "ETC": "Ethereum Classic",
  "ETHD": "Ethereum Dark",
  "ETG": "Ethereum Gold",
  "ETHM": "Ethereum Meta",
  "EMV": "Ethereum Movie Venture",
  "ETHPR": "Ethereum Premium",
  "EQC": "Ethereum Qchain Token",
  "LNK": "Ethereum.Link",
  "BTCE": "EthereumBitcoin",
  "ETF": "EthereumFog",
  "ELITE": "EthereumLite",
  "ETHS": "EthereumScrypt",
  "DIP": "Etherisc",
  "RIYA": "Etheriya",
  "DICE": "Etheroll",
  "FUEL": "Etherparty",
  "ESN": "Ethersocial",
  "ESC": "Ethersportcoin",
  "NEC*": "Ethfinex Nectar Token",
  "ETHIX": "EthicHub",
  "HORSE": "Ethorse",
  "ETHOS": "Ethos",
  "ET4": "Eticket4",
  "EUC": "Eurocoin",
  "ECTE": "EurocoinToken",
  "ERC": "EuropeCoin",
  "EVN*": "EvenCoin",
  "EVENT": "Event Token",
  "EVC": "Eventchain",
  "EGC": "EverGreenCoin",
  "EVER": "EverLife.AI",
  "EVX": "Everex",
  "IQ": "Everipedia",
  "EVR": "Everus",
  "EOC": "EveryonesCoin",
  "EVIL": "EvilCoin",
  "EXB": "ExaByte (EXB)",
  "XUC": "Exchange Union",
  "EXCC": "ExchangeCoin",
  "EXN": "ExchangeN",
  "EXCL": "Exclusive Coin",
  "EXE": "ExeCoin",
  "XNT": "Exenium",
  "EXC": "Eximchain",
  "EXIT": "ExitCoin",
  "EXO": "Exosis",
  "EXP": "Expanse",
  "EXPR": "Expercoin",
  "XP": "Experience Points",
  "EXT*": "Experience Token",
  "EXY": "Experty",
  "EON": "Exscudo",
  "TAURI": "Extauri",
  "EXTN": "Extensive Coin",
  "XTRA": "ExtraCredit",
  "ELT*": "ExtraLovers",
  "XSB": "Extreme Sportsbook",
  "XT": "ExtremeCoin",
  "F16": "F16Coin",
  "FARM": "FARM Coin",
  "FX": "FCoin",
  "FIBRE": "FIBRE",
  "eFIC": "FIC Network",
  "FLASH": "FLASH coin",
  "FLIK": "FLiK",
  "FLM": "FOLM coin",
  "FOREX": "FOREXCOIN",
  "FRED": "FREDEnergy",
  "FREE": "FREE coin",
  "FXP": "FXPay",
  "FABA": "Faba Invest",
  "FT": "Fabric Token",
  "FC": "Facecoin",
  "FACE": "Faceter",
  "FTR": "FactR",
  "FCT": "Factoids",
  "FAIR": "FairCoin",
  "FAIR*": "FairGame",
  "FAIRC": "Faireum Token",
  "FAME": "FameCoin",
  "FAN": "Fan360",
  "FANZ": "FanChain",
  "XFT": "Fantasy Cash",
  "FTM": "Fantom",
  "FCN": "FantomCoin",
  "FRD": "Farad",
  "F2K": "Farm2Kitchen",
  "FTT": "FarmaTrust",
  "FST": "FastCoin",
  "FTUM": "Fatum",
  "DROP*": "FaucetCoin",
  "FAZZ": "FazzCoin",
  "FTC": "FeatherCoin",
  "TIPS": "FedoraCoin",
  "FET": "Fetch.AI",
  "FIH": "Fidelity House",
  "FLC": "Fieldcoin",
  "FIII": "Fiii",
  "FIL": "FileCoin",
  "FFM": "Files.fm Library",
  "FILL": "Fillit",
  "FNTB": "FinTab",
  "FNX": "FinanceX",
  "FIND": "FindCoin",
  "FNL": "Finlocale",
  "FIN": "Finom FIN Token",
  "NOM": "Finom NOM Token",
  "FTX": "FintruX",
  "FIRE": "FireCoin",
  "FLOT": "FireLotto",
  "FRC": "FireRoosterCoin",
  "FFC": "FireflyCoin",
  "1ST": "FirstBlood",
  "FIRST": "FirstCoin",
  "FRST": "FirstCoin",
  "FIST": "FistBump",
  "FIT": "Fitcoin",
  "FRV": "Fitrova",
  "FLAP": "Flappy Coin",
  "FLX": "Flash",
  "FLVR": "FlavorCoin",
  "FNP": "FlipNpik",
  "FLIXX": "Flixxo",
  "FLO": "Flo",
  "FLT": "FlutterCoin",
  "FLUZ": "FluzFluz",
  "FLY": "FlyCoin",
  "FYP": "FlypMe",
  "FOAM": "Foam",
  "FLDC": "Folding Coin",
  "FLLW": "Follow Coin",
  "FNO": "Fonero",
  "FONZ": "FonzieCoin",
  "FOOD": "FoodCoin",
  "FOPA": "Fopacoin",
  "FOR": "Force Coin",
  "XFC": "Forever Coin",
  "FML": "FormulA",
  "FFCT": "FortFC",
  "FOTA": "Fortuna",
  "FSBT": "Forty Seven Bank",
  "FOXT": "Fox Trading",
  "FRAC": "FractalCoin",
  "FRN": "Francs",
  "FRK": "Franko",
  "FRWC": "Frankywillcoin",
  "FRAZ": "FrazCoin",
  "FGZ": "Free Game Zone",
  "FRE": "FreeCoin",
  "FRECN": "Freldo",
  "FREC": "Freyrchain",
  "FSC": "FriendshipCoin",
  "FDZ": "Friendz",
  "FUCK": "Fuck Token",
  "FC2": "Fuel2Coin",
  "FJC": "FujiCoin",
  "NTO": "Fujinto",
  "FLS": "Fuloos Coin",
  "FUNC": "FunCoin",
  "FUN": "FunFair",
  "FUND": "Fund Platform",
  "FUNDZ": "FundFantasy",
  "FND": "FundRequest",
  "FYN": "FundYourselfNow",
  "ATON": "Further Network",
  "FSN*": "Fusion",
  "FSN": "Fusion",
  "FUTC": "FutCoin",
  "FTRC": "FutureCoin",
  "FTP": "FuturePoints",
  "FTW": "FutureWorks",
  "FPC": "Futurepia",
  "FTO": "FuturoCoin",
  "FXT": "FuzeX",
  "FUZZ": "Fuzzballs",
  "GAIA": "GAIA Platform",
  "GAKH": "GAKHcoin",
  "GTX": "GALLACTIC",
  "GMB": "GAMB",
  "GAT": "GATCOIN",
  "GBRC": "GBR Coin",
  "GGP": "GGPro",
  "GGR": "GGRocket",
  "GTO": "GIFTO",
  "GIN": "GINcoin",
  "GIZ": "GIZMOcoin",
  "GMC*": "GMC Coin",
  "GPU": "GPU Coin",
  "GSM": "GSM Coin",
  "GXS": "GXChain",
  "GBO": "Gabro.io",
  "GEP": "Gaia",
  "GNR": "Gainer",
  "ORE": "Galactrum",
  "GES": "Galaxy eSolutions",
  "GLX": "GalaxyCoin",
  "GALI": "Galilel",
  "GLN": "Galion Token",
  "GAM": "Gambit coin",
  "GMCN": "GambleCoin",
  "GTC": "Game",
  "GXT": "Game Protocol",
  "GBT": "GameBetCoin",
  "GML": "GameLeagueCoin",
  "GST": "GameStars",
  "UNITS": "GameUnits",
  "GX": "GameX",
  "GAME": "Gamecredits",
  "GDX": "Gamedex",
  "FLP": "Gameflip",
  "GNJ": "GanjaCoin V2",
  "GAP": "Gapcoin",
  "GRLC": "Garlicoin",
  "GAS": "Gas",
  "FORK": "Gastro Advisor Token",
  "GBA": "Geeba",
  "GEMA": "Gemera",
  "GUSD": "Gemini Dollar",
  "GEM": "Gems",
  "GMS": "Gemstra",
  "GEMZ": "Gemz Social",
  "GXC*": "GenXCoin",
  "GNX": "Genaro Network",
  "GENX": "Genesis Network",
  "GVT": "Genesis Vision",
  "XGS": "GenesisX",
  "GSY": "GenesysCoin",
  "GEN": "Genstake",
  "GEO": "GeoCoin",
  "GUNS": "GeoFunders",
  "GEON": "Geon",
  "GER": "GermanCoin",
  "SPKTR": "Ghost Coin",
  "GHC": "GhostCoin",
  "GHOUL": "Ghoul Coin",
  "GIC": "Giant",
  "GIFT": "GiftNet",
  "GFT": "Giftcoin",
  "GIG": "GigCoin",
  "GBTC": "GigTricks",
  "WTT": "Giga Watt",
  "GZB": "Gigzi",
  "GGS": "Gilgam",
  "GIM": "Gimli",
  "GMR": "Gimmer",
  "GGC": "Gingr",
  "GOT": "Giotto Coin",
  "GIVE": "GiveCoin",
  "GLA": "Gladius",
  "GLOBE": "Global",
  "GCR": "Global Currency Reserve",
  "GJC": "Global Jobcoin",
  "GSC": "Global Social Chain",
  "GTC*": "Global Tour Coin",
  "GTIB": "Global Trust Coin",
  "BSTY": "GlobalBoost",
  "GLC": "GlobalCoin",
  "GLT": "GlobalToken",
  "GVE": "Globalvillage Ecosystem",
  "GSI": "Globex SCI",
  "GBXT": "Globitex Token",
  "GSX": "GlowShares",
  "GLYPH": "GlyphCoin",
  "GNO": "Gnosis",
  "xGOx": "Go!",
  "GBX": "GoByte",
  "GO": "GoChain",
  "GOT*": "GoToken",
  "GOA": "GoaCoin",
  "GOAL": "Goal Bonanza",
  "GOAT": "Goat",
  "GBE": "Godbex",
  "GDL": "GodlyCoin",
  "XR": "Gofind XR",
  "GPL": "Gold Pressed Latinum",
  "GRX": "Gold Reward Token",
  "GB": "GoldBlocks",
  "GLD": "GoldCoin",
  "MNTP": "GoldMint",
  "GP": "GoldPieces",
  "XGR": "GoldReserve",
  "GMA": "Goldchip Mining Asset",
  "GEA": "Goldea",
  "XGH": "Golden Hash",
  "XGB": "GoldenBird",
  "GLDR": "Golder Coin",
  "GMX": "Goldmaxcoin",
  "GNT": "Golem Network Token",
  "GOLF": "GolfCoin",
  "GOLOS": "Golos",
  "GBG": "Golos Gold",
  "GOOD": "GoodCoin",
  "GOOD*": "Goodomy",
  "GOON": "Goonies",
  "BUCKS*": "GorillaBucks",
  "GOTX": "GothicCoin",
  "GRFT": "Graft Blockchain",
  "GDC": "GrandCoin",
  "GAI": "GraphGrail AI",
  "77G": "GraphenTech",
  "GRAV": "Graviton",
  "GBIT": "GravityBit",
  "WPP": "Green Energy Token",
  "GRE": "GreenCoin",
  "GRMD": "GreenMed",
  "GEX": "GreenX",
  "GNC": "Greencoin",
  "GTN": "Greentoken",
  "GREXIT": "GrexitCoin",
  "GC": "Gric Coin",
  "GRID": "Grid+",
  "GRC": "GridCoin",
  "GRM": "GridMaster",
  "GMC": "Gridmaster",
  "GRIN": "Grin",
  "GRS": "Groestlcoin",
  "GRO": "Gron Digital",
  "GRWI": "Growers International",
  "GROW": "GrownCoin",
  "GRW": "GrowthCoin",
  "GTR": "Gturbo",
  "GET": "Guaranteed Entrance Token",
  "GETX": "Guaranteed Ethurance Token Extra",
  "GUAR": "Guarium",
  "GCC": "GuccioneCoin",
  "GUE": "GuerillaCoin",
  "NLG": "Gulden",
  "GUN": "GunCoin",
  "GUP": "Guppy",
  "GXC": "Gx Coin",
  "HIDU": "H-Education World",
  "HART": "HARA",
  "HBZ": "HBZ Coin",
  "HIX": "HELIX Orange",
  "HELL": "HELL COIN",
  "HRO": "HEROIC.com",
  "PLAY": "HEROcoin",
  "HOLD": "HOLD",
  "HLDY": "HOLIDAY",
  "HQX": "HOQU",
  "HODL": "HOdlcoin",
  "HTML": "HTML Coin",
  "HTML5": "HTML5 Coin",
  "HUS": "HUSSY",
  "HYC": "HYCON",
  "HYGH": "HYGH",
  "HKN": "Hacken",
  "HKG": "Hacker Gold",
  "HAC": "Hackspace Capital",
  "HPAY": "HadePay",
  "HLC": "Halal-Chain",
  "HAL": "Halcyon",
  "HALLO": "Halloween Coin",
  "HALO": "Halo Platform",
  "HMT": "Hamster Marketplace Token",
  "HAMS": "HamsterCoin",
  "HANA": "Hanacoin",
  "HPC": "HappyCoin",
  "HCC": "HappyCreatorCoin",
  "HRBE": "Harambee Token",
  "HRB": "Harbour DAO",
  "HMN": "Harvest Masternode Coin",
  "HSC": "HashCoin",
  "HGS": "HashGains",
  "HASH": "Hashbon",
  "GARD": "Hashgard",
  "XHV": "Haven Protocol",
  "HAT": "Hawala.Exchange",
  "HZT": "HazMatCoin",
  "HAZE": "HazeCoin",
  "HDAC": "Hdac",
  "HHEM": "Healthureum",
  "WORM": "HealthyWorm",
  "HB": "HeartBout",
  "HEAT": "Heat Ledger",
  "HVC": "HeavyCoin",
  "HDG": "Hedge Token",
  "HEDG": "HedgeTrade",
  "HEDGE": "Hedgecoin",
  "HEEL": "HeelCoin",
  "HYS": "Heiss Shares",
  "HLM": "Helium",
  "HLX": "Helix3",
  "HNC": "Hellenic Coin",
  "HGT": "Hello Gold",
  "HMP": "HempCoin",
  "HERB": "HerbCoin",
  "HERO": "Hero",
  "HER": "Hero Node",
  "HETA": "HetaChain",
  "HEX": "HexCoin",
  "HXC": "HexanCoin",
  "HXT": "HextraCoin",
  "HXX": "HexxCoin",
  "HMC": "Hi Mutual Society",
  "XHI": "HiCoin",
  "HIH": "HiHealth",
  "HPB": "High Performance Blockchain",
  "HVCO": "High Voltage Coin",
  "AIMS": "HighCastle Token",
  "HV": "HighVibe.Network",
  "HGO": "HireGo",
  "HIRE": "HireMatch",
  "HFT": "Hirefreehands",
  "HIT": "HitChain",
  "HTC": "Hitcoin",
  "HIVE": "Hive",
  "HVN": "Hiveterminal Token",
  "HBN": "HoboNickels",
  "HWC": "HollyWoodCoin",
  "HOT*": "Holo",
  "HBC": "HomeBlockCoin",
  "HMD": "Homelend",
  "HONEY": "Honey",
  "HZ": "Horizon",
  "HSP": "Horse Power",
  "HORUS": "HorusPay",
  "HYT": "HoryouToken",
  "HSR": "Hshare",
  "HBT": "Hubii Network",
  "HMQ": "Humaniq",
  "HNC*": "Huncoin",
  "HUC": "HunterCoin",
  "HT": "Huobi Token",
  "HUR": "Hurify",
  "HUSH": "Hush",
  "HOT": "Hydro Protocol",
  "HYDRO": "Hydrogen",
  "H2O": "Hydrominer",
  "H3O": "Hydrominer",
  "HC": "HyperCash",
  "HYPER": "HyperCoin",
  "HLD": "HyperLending",
  "HQT": "HyperQuant",
  "HBX": "Hyperbridge",
  "TREE": "HyperionX",
  "HPSP": "Hyperspace",
  "HYP": "Hyperstake",
  "IHT": "I-House Token",
  "I0C": "I0coin",
  "IAG": "IAGON",
  "IAM": "IAME Identity",
  "ICASH": "ICASH",
  "ICOO": "ICO OpenLedger",
  "ICOS": "ICOBox",
  "ICX": "ICON Project",
  "ICST": "ICST",
  "IDAC": "IDAC",
  "IDAP": "IDAP",
  "IDXM": "IDEX Membership",
  "IDM": "IDM",
  "IG": "IG Token",
  "IGTT": "IGT",
  "ILC": "ILCoin",
  "ILCT": "ILCoin Token",
  "IML": "IMMLA",
  "ITR": "INTRO",
  "IOC": "IOCoin",
  "IOST": "IOS token",
  "IOT": "IOTA",
  "IOTW": "IOTW",
  "IOUX": "IOU",
  "IOU": "IOU1",
  "IOV": "IOV",
  "IPSX": "IP Exchange",
  "IPC*": "IPChain",
  "IQN": "IQeon",
  "IRC": "IRONCOIN",
  "IVN": "IVN Security",
  "IXC": "IXcoin",
  "IZX": "IZX",
  "ROCK2": "Ice Rock Mining",
  "ICB": "IceBergCoin",
  "ICHX": "IceChain",
  "ICOB": "Icobid",
  "ICON": "Iconic",
  "ICN": "Iconomi",
  "IDC": "IdealCoin",
  "IGNIS": "Ignis",
  "IC": "Ignition",
  "REX": "Imbrex",
  "IMGZ": "Imigize",
  "IMVR": "ImmVRse",
  "IMX": "Impact",
  "IMPCH": "Impeach",
  "IPC": "ImperialCoin",
  "XIM": "Impresso",
  "IMPS": "Impulse Coin",
  "IN": "InCoin",
  "INX": "InMax",
  "NKA": "IncaKoin",
  "INCNT": "Incent",
  "INCP": "InceptionCoin",
  "INC": "Incrementum",
  "IDH": "IndaHash",
  "IMS": "Independent Money System",
  "ERC20": "Index ERC20",
  "INDI": "IndiCoin",
  "IND": "Indorse",
  "IFX": "Infinex",
  "IFC": "Infinite Coin",
  "XIN": "Infinity Economics",
  "INF8": "Infinium-8",
  "IFLT": "InflationCoin",
  "IFUM": "Infleum",
  "INFLR": "Inflr",
  "INTO": "Influ Token",
  "INFX": "Influxcoin",
  "INK": "Ink",
  "XNK": "Ink Protocol",
  "ILK": "Inlock",
  "SOUND": "Inmusik",
  "INN": "Innova",
  "MINX": "InnovaMinex",
  "INSN": "Insane Coin",
  "INSANE": "InsaneCoin",
  "WOLF": "Insanity Coin",
  "INSTAR": "Insights Network",
  "INS": "Insolar",
  "ICC": "Insta Cash Coin",
  "MINE": "Instamine Nuggets",
  "SPON": "Instant Sponsor Token",
  "INSUR": "InsurChain Coin",
  "IPL": "InsurePal",
  "ISR": "Insureum",
  "IQB": "Intelligence Quotient Benefit",
  "ITT": "Intelligent Trading",
  "XID*": "International Diamond Coin",
  "INT": "Internet Node Token",
  "IOP": "Internet of People",
  "INXT": "Internxt",
  "ISH": "Interstellar Holdings",
  "ITZ": "Interzone",
  "ICT": "Intrachain",
  "INV*": "Invacio",
  "IDT": "InvestDigital",
  "IFT": "InvestFeed",
  "INVX": "Investx",
  "IVC": "Investy Coin",
  "INV": "Invictus",
  "IHF": "Invictus Hyperion Fund",
  "IVZ": "InvisibleCoin",
  "INVOX": "Invox Finance",
  "IZA": "Inzura",
  "ITC": "IoT Chain",
  "IOTX": "IoTeX Network",
  "ION": "Ionomy",
  "TLU": "Irene Energy",
  "IRL": "IrishCoin",
  "ISL": "IslaCoin",
  "ITL": "Italian Lira",
  "ITA": "Italocoin",
  "ING": "Iungo",
  "IEC": "IvugeoEvolutionCoin",
  "IVY": "IvyKoin",
  "IWT": "IwToken",
  "J8T": "JET8",
  "JEX": "JEX Token",
  "JIO": "JIO Token",
  "JOYS": "JOYS",
  "JOY*": "JOYSO",
  "JSE": "JSEcoin",
  "JANE": "JaneCoin",
  "JNS": "Janus",
  "JVY": "Javvy",
  "JC": "JesusCoin",
  "JET": "Jetcoin",
  "JWL": "Jewels",
  "JIB": "Jibbit",
  "JNT": "Jibrel Network Token",
  "JIF": "JiffyCoin",
  "JCR": "Jincor",
  "JINN": "Jinn",
  "JOBS": "JobsCoin",
  "J": "JoinCoin",
  "JOINT": "Joint Ventures",
  "JOK": "JokerCoin",
  "XJO": "JouleCoin",
  "JOYT": "JoyToken",
  "JOY": "Joycoin",
  "JUDGE": "JudgeCoin",
  "JBS": "JumBucks Coin",
  "JUMP": "Jumpcoin",
  "JKC": "JunkCoin",
  "JMC": "Junson Ming Chan Coin",
  "JDC": "JustDatingSite",
  "KSYS": "K-Systems",
  "KAAS": "KAASY.AI",
  "KAT": "KATZcoin",
  "KEC": "KEYCO",
  "KIBIS": "KIBIS",
  "TOS": "KRATOS",
  "KRC": "KRCoin",
  "KREDS": "KREDS",
  "KUBO": "KUBO",
  "KWH": "KWHCoin",
  "KZC": "KZCash",
  "KLKS": "Kalkulus",
  "KAPU": "Kapu",
  "KBC": "Karatgold coin",
  "KRB": "Karbo",
  "KRM": "Karma",
  "KARMA": "Karma",
  "K2G": "Kasko2go",
  "KAYI": "Kayı",
  "KCASH": "Kcash",
  "KEK": "KekCoin",
  "KEN": "Kencoin",
  "KEP": "Kepler",
  "KC": "Kernalcoin",
  "KETAN": "Ketan",
  "KEX": "KexCoin",
  "KEY*": "KeyCoin",
  "KMX": "KiMex",
  "KICK": "KickCoin",
  "KLC": "KiloCoin",
  "KIN": "Kin",
  "KIND": "Kind Ads",
  "KVT": "Kinesis Velocity Token",
  "KING": "King93",
  "KNC**": "KingN Coin",
  "MEOW": "Kittehcoin",
  "KLK": "Klickzie",
  "KED": "Klingon Empire Darsek",
  "KDC": "Klondike Coin",
  "KNW": "Knowledge",
  "KOBO": "KoboCoin",
  "KOLION": "Kolion",
  "KMD": "Komodo",
  "KORE": "Kore",
  "KOTO": "Koto",
  "KUSD": "Kowala",
  "KRAK": "Kraken",
  "KRONE": "Kronecoin",
  "KSS": "Krosscoin",
  "KGC": "KrugerCoin",
  "KRL": "Kryll",
  "KTK": "KryptCoin",
  "KRP": "Kryptoin",
  "KR": "Krypton",
  "KBX": "KuBitX",
  "KBR": "Kubera Coin",
  "KUBOS": "KubosCoin",
  "KCS": "Kucoin",
  "KUE": "Kuende",
  "KURT": "Kurrent",
  "KUSH": "KushCoin",
  "KUV": "Kuverit",
  "KVT*": "Kvantor",
  "KNC": "Kyber Network",
  "LAX": "LAPO",
  "LA": "LATOKEN",
  "LBC": "LBRY Credits",
  "LAO": "LC Token",
  "LEO": "LEOcoin",
  "LGBTQ": "LGBTQoin",
  "LHC": "LHCoin",
  "LIFE": "LIFE",
  "LN": "LINK",
  "LNKC": "LINKCHAIN",
  "VEEN": "LIVEEN",
  "LIPC": "LIpcoin",
  "LTBC": "LTBCoin",
  "LTO": "LTO Network",
  "LUMA": "LUMA Token",
  "LUX": "LUXCoin",
  "LVX": "LVX",
  "LYN": "LYNCHPIN Token",
  "LALA": "LaLa World",
  "LBR": "LaborCrypto",
  "LAB": "Labrys",
  "BAC*": "LakeBanker",
  "TAU": "Lamden Tau",
  "PIX": "Lampix",
  "LANA": "LanaCoin",
  "AXIS": "LaneAxis",
  "LTH": "Lathaan",
  "LAT": "Latium",
  "LATX": "LatiumX",
  "LAZ": "Lazarus",
  "LEPEN": "LePenCoin",
  "LEA": "LeaCoin",
  "LDC": "LeadCoin",
  "LEAF": "LeafCoin",
  "LGD": "Legends Cryptocurrency",
  "LGO": "Legolas Exchange",
  "LELE": "Lelecoin",
  "LEMON": "LemonCoin",
  "LCT": "LendConnect",
  "LND": "Lendingblock",
  "LOAN": "Lendoit",
  "LST": "Lendroid Support Token",
  "LENIN": "LeninCoin",
  "LIR": "Let it Ride",
  "LTHN": "Lethean",
  "LVL*": "LevelNet Token",
  "LVG": "Leverage Coin",
  "LEV": "Leverj",
  "XLC": "LeviarCoin",
  "LIB": "Libellum",
  "XLB": "LibertyCoin",
  "LBA": "Libra Credit",
  "LXC": "LibrexCoin",
  "LIGER": "Ligercoin",
  "LSD": "LightSpeedCoin",
  "LPC*": "Lightpaycoin",
  "LIKE": "LikeCoin",
  "LK": "Liker",
  "LIMX": "LimeCoinX",
  "LTD": "Limited Coin",
  "LINDA": "Linda",
  "LET": "LinkEye",
  "LNC*": "Linker Coin",
  "LINX": "Linx",
  "LIPS": "LipChain",
  "LEN": "Liqnet",
  "LQD": "Liquid",
  "LQ8": "Liquid8",
  "LQDN": "Liquidity Network",
  "LSK": "Lisk",
  "LTCC": "Listerclassic Coin",
  "LBTC": "LiteBitcoin",
  "LTG": "LiteCoin Gold",
  "LTCU": "LiteCoin Ultra",
  "LCWP": "LiteCoinW Plus",
  "LTCR": "LiteCreed",
  "LDOGE": "LiteDoge",
  "LTB": "Litebar",
  "LTC": "Litecoin",
  "LTCH": "Litecoin Cash",
  "LCP": "Litecoin Plus",
  "LCASH": "LitecoinCash",
  "LCC": "LitecoinCash",
  "LTCD": "LitecoinDark",
  "LTCP": "LitecoinPro",
  "LTCX": "LitecoinX",
  "LTZ": "Litecoinz",
  "LNT": "Litenett",
  "LTS": "Litestar Coin",
  "LIT": "Lithium",
  "LITION": "Lition",
  "LTA": "Litra",
  "LPC": "Little Phil",
  "LIVE": "Live Stars",
  "LVN": "LivenPay",
  "LPT": "Livepeer",
  "LIV": "LiviaCoin",
  "LIZ": "Lizus Payment",
  "LTE": "Local Token Exchange",
  "LWF": "Local World Forwarders",
  "LCS": "LocalCoinSwap",
  "LOCI": "LociCoin",
  "LOC*": "LockTrip",
  "LOC": "Loco",
  "LGR": "Logarithm",
  "LOKI": "Loki",
  "LLG": "Loligo",
  "LMC": "LomoCoin",
  "LOOK": "LookCoin",
  "LOOM": "Loom Network",
  "LRC": "Loopring",
  "LOT": "LottoCoin",
  "LYK": "Loyakk Vega",
  "LYL": "LoyalCoin",
  "BASH": "LuckChain",
  "LCK": "Luckbox",
  "LK7": "Lucky7Coin",
  "LUCKY": "LuckyBlocks",
  "LKY": "LuckyCoin",
  "LCR": "Lucre",
  "LDM": "Ludum token",
  "LNL": "LunarLink",
  "LUN": "Lunyr",
  "LC": "Lutetium Coin",
  "LUX**": "Luxmi Coin",
  "LYC": "LycanCoin",
  "LDN": "Lydiancoin",
  "LYFE": "Lyfe",
  "LKK": "Lykke",
  "LYM": "Lympo",
  "LYNK": "Lynked.World",
  "LYNX": "Lynx",
  "LYB": "LyraBar",
  "M2O": "M2O Token",
  "MDN": "MADANA",
  "MAKE": "MAKE",
  "MRK": "MARK.SPACE",
  "MCAP": "MCAP",
  "MCV": "MCV Token",
  "MTEL": "MEDoctor",
  "MEETONE": "MEET.ONE",
  "MFX": "MFChain",
  "MIMI": "MIMI Money",
  "MIODIO": "MIODIOCOIN",
  "MIS": "MIScoin",
  "MILC": "MIcro Licensing Coin",
  "MMNXT": "MMNXT",
  "MMO": "MMOCoin",
  "MMXVI": "MMXVI",
  "MOAC": "MOAC",
  "MOBU": "MOBU",
  "MODEX": "MODEX Token",
  "MOS": "MOS Coin",
  "XDMC": "MPCX",
  "MSD": "MSD",
  "MTCMN": "MTC Mesh",
  "MUN": "MUNcoin",
  "MUSD": "MUSDcoin",
  "MUST": "MUST Protocol",
  "MVL": "MVL",
  "YCE": "MYCE",
  "MAC": "MachineCoin",
  "MCRN": "MacronCoin",
  "MRV": "Macroverse",
  "MDC*": "MadCoin",
  "ART": "Maecenas",
  "MAP": "Maester Protocol",
  "MAG**": "Maggie Token",
  "MGN": "MagnaCoin",
  "MAG": "Magnet",
  "MAG*": "Magos",
  "MAID": "MaidSafe Coin",
  "MMXIV": "MaieutiCoin",
  "MNC": "MainCoin",
  "MFT": "Mainframe",
  "MSC*": "MaisCoin",
  "MIV": "MakeItViral",
  "MKR": "Maker",
  "MAT*": "Manet Coin",
  "MANNA": "Manna",
  "MAPC": "MapCoin",
  "MAR": "MarijuanaCoin",
  "MASP": "Market.space",
  "MRS": "MarsCoin",
  "MARS": "MarsCoin",
  "MXT": "MartexCoin",
  "MARV": "Marvelous",
  "MARX": "MarxCoin",
  "MARYJ": "MaryJane Coin",
  "MSR": "Masari",
  "MC": "Mass Coin",
  "MASS": "Mass.Cloud",
  "MGD": "MassGrid",
  "MCAR": "MasterCar",
  "MSC": "MasterCoin",
  "MM": "MasterMint",
  "MTR": "MasterTraderCoin",
  "MAN*": "Matrix AI Network",
  "MTX": "Matryx",
  "MPG": "Max Property Group",
  "MAX": "MaxCoin",
  "MYC": "MayaCoin",
  "MZC": "MazaCoin",
  "MBIT": "Mbitbooks",
  "MLITE": "MeLite",
  "MDT*": "Measurable Data Token",
  "MED*": "MediBloc",
  "MEDI": "MediBond",
  "MCU": "MediChain",
  "MDS": "MediShares",
  "MNT*": "Media Network Coin",
  "MPT": "Media Protocol Token",
  "MEDX": "Mediblock",
  "MDC": "MedicCoin",
  "MEDIC": "MedicCoin",
  "MTN*": "Medicalchain",
  "MHP": "MedicoHealth",
  "MED": "MediterraneanCoin",
  "MPRO": "MediumProject",
  "MEC": "MegaCoin",
  "MEGA": "MegaFlash",
  "XMS": "Megastake",
  "MEL": "Melior AI",
  "MLN": "Melon",
  "MBN": "Membrana",
  "MET": "Memessenger",
  "EMT*": "Memority",
  "MMC": "MemoryCoin",
  "MPAY": "Menapay",
  "ONE": "Menlo One",
  "MENU": "MenuBuzz",
  "MRN": "Mercoin",
  "MVP": "Merculet",
  "MER": "Mercury",
  "GMT": "Mercury Protocol",
  "MHC": "MetaHash",
  "METM": "MetaMorph",
  "META": "Metadium",
  "MTL": "Metal",
  "MTLM3": "Metal Music v3",
  "METAL": "MetalCoin",
  "ETP": "Metaverse",
  "MET*": "Metronome",
  "MIT": "MiMiner",
  "MBTC": "MicroBitcoin",
  "AMM": "MicroMoney",
  "MDT": "Midnight",
  "MON": "MilionCoin",
  "MUU": "MilkCoin",
  "MIL": "Milllionaire Coin",
  "MILO": "MiloCoin",
  "MINC": "MinCoin",
  "MG": "Mind Gene",
  "MND": "MindCoin",
  "MIC": "Mindexcoin",
  "MAI": "Mindsync",
  "MINT*": "Mineable Token",
  "MIO": "Miner One token",
  "MIN": "Minerals Coin",
  "MNE": "Minereum",
  "MRT": "MinersReward",
  "MNM": "Mineum",
  "MINEX": "Minex",
  "MNX": "MinexCoin",
  "MAT": "MiniApps",
  "MNTS": "Mint",
  "MINT": "MintCoin",
  "BIP": "Minter",
  "MITH": "Mithril",
  "XIN*": "Mixin",
  "MIB": "Mobile Integrated Blockchain",
  "CHF*": "MobileBridge Momentum",
  "MGO": "MobileGo",
  "MOLK": "Mobilink Token",
  "MOBI": "Mobius",
  "MTRC": "ModulTrade",
  "MDL*": "Modulum",
  "MOD": "Modum",
  "MDA": "Moeda",
  "MOIN": "MoinCoin",
  "MOJO": "Mojocoin",
  "MOF": "Molecular Future",
  "MOL": "Molecule",
  "TAB": "MollyCoin",
  "MMTM": "Momentum",
  "MONA": "MonaCoin",
  "MNZ": "Monaize",
  "XMR": "Monero",
  "ZMR": "Monero 0",
  "XMC": "Monero Classic",
  "XMRG": "Monero Gold",
  "XMO": "Monero Original",
  "XMV": "MoneroV",
  "MONETA": "Moneta",
  "MNV": "MonetaVerde",
  "MUE": "MonetaryUnit",
  "MTH": "Monetha",
  "MTZ": "Monetizr",
  "MNB": "MoneyBag",
  "MONEY": "MoneyCoin",
  "MRP*": "MoneyRebel",
  "IMT": "MoneyToken",
  "MNY": "Monkey",
  "MONK": "Monkey Project",
  "XMCC": "Monoeci",
  "MNR": "Monoreto",
  "MBI": "Monster Byte Inc",
  "MBLC": "Mont Blanc",
  "MOON": "MoonCoin",
  "LX": "Moonlight",
  "MZG": "Moozicore",
  "MITX": "Morpheus Infrastructure Token",
  "MRPH": "Morpheus Network",
  "MRP": "MorpheusCoin",
  "MZX": "Mosaic Network",
  "MOAT": "Mother Of All Tokens",
  "MSP": "Mothership",
  "XMN": "Motion",
  "MTN**": "Motion",
  "MOTO": "Motocoin",
  "MOV": "MovieCoin",
  "MTK": "Moya Token",
  "MRSA": "MrsaCoin",
  "MUDRA": "MudraCoin",
  "MTT": "MulTra",
  "MLT": "MultiGames",
  "MWC": "MultiWallet Coin",
  "MBT": "Multibot",
  "MTCN": "Multiven",
  "MRY": "MurrayCoin",
  "MUSE": "Muse",
  "MITC": "MusicLife",
  "MUSIC": "Musicoin",
  "MCI": "Musiconomi",
  "MST": "MustangCoin",
  "MUT": "Mutual Coin",
  "MBC": "My Big Coin",
  "MYB": "MyBit",
  "MCB": "MyCryptoBank",
  "MYDFS": "MyDFS",
  "MAZC": "MyMazzu",
  "MT*": "MyToken",
  "WISH": "MyWish",
  "MT": "Mycelium Token",
  "MYO": "Mycro",
  "MPXT": "Myplacex",
  "XMY": "MyriadCoin",
  "MYST": "Mysterium",
  "NANJ": "NANJCOIN",
  "XEM": "NEM",
  "NEO": "NEO",
  "NEOG": "NEO Gold",
  "NEXO": "NEXO",
  "NOX": "NITRO",
  "NIX": "NIX",
  "NKN": "NKN",
  "NOAH": "NOAHCOIN",
  "NBAR": "NOBAR",
  "NOIA": "NOIA Network",
  "NOIZ": "NOIZ",
  "CHFN": "NOKU CHF",
  "EURN": "NOKU EUR",
  "NOKU": "NOKU Master token",
  "NSP": "NOMAD.space",
  "NOW": "NOW Token",
  "NPC": "NPCcoin",
  "NPER": "NPER",
  "NVST": "NVO",
  "NWP": "NWPSolution",
  "NXE": "NXEcoin",
  "NXTI": "NXTI",
  "NXTTY": "NXTTY",
  "NYN": "NYNJA",
  "NYX": "NYXCOIN",
  "NFN": "Nafen",
  "NGC": "NagaCoin",
  "NZE": "Nagezeni",
  "NKT": "NakomotoDark",
  "NAM": "Namacoin",
  "NMH": "Namahe",
  "NMC": "Namecoin",
  "NMK": "Namek",
  "NAMO": "NamoCoin",
  "NANO": "Nano",
  "NHCT": "Nano Healthcare Token",
  "NAN": "NanoToken",
  "NPX": "Napoleon X",
  "NRVE": "Narrative",
  "NAS2": "Nas2Coin",
  "NAUT": "Nautilus Coin",
  "NAV": "NavCoin",
  "NAVI": "NaviAddress",
  "NAVIB": "Navibration",
  "NEBL": "Neblio",
  "NEBU": "Nebuchadnezzar",
  "NBAI": "Nebula AI",
  "NAS": "Nebulas",
  "NDC*": "NeedleCoin",
  "NEF": "NefariousCoin",
  "NRX": "Neironix",
  "NEC": "NeoCoin",
  "NEX": "Neonexchange",
  "NEOS": "NeosCoin",
  "NTCC": "NeptuneClassic",
  "NBIT": "NetBit",
  "NET": "NetCoin",
  "NTM": "NetM",
  "NETKO": "Netko",
  "OUT": "Netscouters",
  "NTWK": "Network Token",
  "NETC": "NetworkCoin",
  "NEU*": "NeuCoin",
  "NEU": "Neumark",
  "NRP": "Neural Protocol",
  "NRO": "Neuro",
  "NRC": "Neurocoin",
  "NRM": "Neuromachine",
  "NTK": "Neurotoken",
  "NTRN": "Neutron",
  "NEVA": "NevaCoin",
  "NDC": "NeverDie",
  "NIC": "NewInvestCoin",
  "NYC": "NewYorkCoin",
  "NZC": "NewZealandCoin",
  "NEWB": "Newbium",
  "NEW": "Newton",
  "NCP": "Newton Coin",
  "NXC": "Nexium",
  "NEXT": "Next.exchange Token",
  "Pakka": "NextPakk",
  "NXS": "Nexus",
  "NEXXO": "Nexxo",
  "NGIN": "Ngin",
  "NICE": "NiceCoin",
  "NIHL": "Nihilo Coin",
  "NMB": "Nimbus Coin",
  "NIMFA": "Nimfamoney",
  "NIM": "Nimiq",
  "NTC": "NineElevenTruthCoin",
  "NDOGE": "NinjaDoge",
  "NBR": "Niobio Cash",
  "NBC": "Niobium",
  "$NOBS": "No BS Crypto",
  "NLC": "NoLimitCoin",
  "NLC2": "NoLimitCoin",
  "NOBL": "NobleCoin",
  "NODE": "Node",
  "NODIS": "Nodis",
  "NVDX": "Nodvix",
  "NRB": "NoirBits",
  "NRS": "NoirShares",
  "NUSD": "Nomin USD",
  "NZO": "NonZero",
  "NOO": "Noocoin",
  "NVC": "NovaCoin",
  "MNVM": "Novam",
  "NWCN": "NowCoin",
  "NBX": "Noxbox",
  "NBT": "NuBits",
  "NSR": "NuShares",
  "NUBIS": "NubisCoin",
  "NCASH": "Nucleus Vision",
  "NUKE": "NukeCoin",
  "NKC": "Nukecoinz",
  "NLX": "Nullex",
  "NULS": "Nuls",
  "N7": "Number7",
  "NUM": "NumbersCoin",
  "NMR": "Numeraire",
  "XNC*": "Numismatic Collections",
  "NMS": "Numus",
  "NXT": "Nxt",
  "NYAN": "NyanCoin",
  "NBL": "Nybble",
  "OATH": "OATH Protocol",
  "ODE": "ODEM",
  "ODMC": "ODMCoin",
  "OK": "OKCash",
  "OKOIN": "OKOIN",
  "ONAM": "ONAM",
  "OPC": "OP Coin",
  "OPP*": "OPP Open WiFi",
  "ORET": "ORET Token",
  "ORM": "ORIUM",
  "ORS": "ORS Group",
  "OASC": "Oasis City",
  "OBITS": "Obits Coin",
  "OBS": "Obscurebay",
  "ODN": "Obsidian",
  "OCL": "Oceanlab",
  "OTX": "Octanox",
  "OCTO*": "OctoBit Coin",
  "OCTO": "OctoCoin",
  "OWC": "Oduwa",
  "OCN": "Odyssey",
  "OFCR": "OfficerCoin",
  "OJX": "Ojooo",
  "OKB": "Okex",
  "OLM": "Olam",
  "ODNT": "Old Dogs New Tricks",
  "OLDSF": "OldSafeCoin",
  "OLV": "OldV",
  "OLE": "Olive",
  "OLYMP": "OlympCoin",
  "MOT": "Olympus Labs",
  "OMA": "OmegaCoin",
  "OMGC": "OmiseGO Classic",
  "OMG": "OmiseGo",
  "OMNI": "Omni",
  "OMC": "OmniCron",
  "ECOM": "Omnitude",
  "ONL": "On.Live",
  "OGT": "One Game",
  "OSF": "One Solution",
  "OLT": "OneLedger",
  "RNT": "OneRoot Network",
  "ONX": "Onix",
  "OIO": "Online",
  "ONT": "Ontology",
  "ONGAS": "Ontology Gas",
  "OPQ": "Opacity",
  "XPO": "Opair",
  "OPAL": "OpalCoin",
  "OPEN": "Open Platform",
  "OTN": "Open Trading Network",
  "OAX": "OpenANX",
  "BRIX": "OpenBrix",
  "CHAT": "OpenChat",
  "OSC": "OpenSourceCoin",
  "ZNT": "OpenZen",
  "OPES": "Opes",
  "OPP": "Opporty",
  "OPEX": "Optherium Token",
  "OSA": "Optimal Shelf Availability Token",
  "OPTION": "OptionCoin",
  "OPU": "Opu Coin",
  "OPT": "Opus",
  "OCT": "OracleChain",
  "OC": "OrangeCoin",
  "ORBS": "Orbis",
  "ORB": "Orbitcoin",
  "RDC": "Ordocoin",
  "ORGT": "Organic Token",
  "ORC": "Organicco",
  "ORI": "Origami",
  "ORS*": "OriginSport",
  "TRAC": "OriginTrail",
  "OCC": "Original Crypto Coin",
  "ORLY": "OrlyCoin",
  "ORME": "Ormeus Coin",
  "ORO": "OroCoin",
  "OROC": "Orocrypt",
  "ORV": "Orvium",
  "OICOIN": "Osmium Investment Coin",
  "OS76": "OsmiumCoin",
  "OWD": "Owlstand",
  "ZXC": "Oxcert",
  "OXY": "Oxycoin",
  "PRL": "Oyster Pearl",
  "OYS": "Oyster Platform",
  "SHL": "Oyster Shell",
  "P2PS": "P2P Solutions Foundation",
  "GENE": "PARKGENE",
  "PAT": "PATRON",
  "PAXEX": "PAXEX",
  "PQT": "PAquarium",
  "PAI": "PCHAIN",
  "PGF7T": "PGF500",
  "PHI": "PHI Token",
  "PITCH": "PITCH",
  "PLNC": "PLNCoin",
  "PCH": "POPCHAIN",
  "PPOVR": "POVR",
  "TOSS": "PROOF OF TOSS",
  "PROUD": "PROUD Money",
  "PROOF": "PROVER",
  "PSI": "PSIcoin",
  "PVP": "PVPChain",
  "PWR": "PWR Coin",
  "PX": "PXcoin",
  "PCS": "Pabyosi Coin",
  "PBC": "PabyosiCoin",
  "PAC": "PacCoin",
  "PAK": "Pakcoin",
  "PLMT": "Pallium",
  "PND": "PandaCoin",
  "PINKX": "PantherCoin",
  "PAN": "Pantos",
  "PRT": "Papusha",
  "PRP": "Papyrus",
  "PRG": "Paragon",
  "DUO": "ParallelCoin",
  "PARA": "ParanoiaCoin",
  "PARETO": "Pareto Network Token",
  "PKB": "ParkByte",
  "PAR": "Parlay",
  "PART": "Particl",
  "PASC": "Pascal Coin",
  "PASL": "Pascal Lite",
  "PAS": "Passive Coin",
  "PTO": "Patentico",
  "PTOY": "Patientory",
  "PAVO": "Pavocoin",
  "PAX": "Paxos Standard",
  "PBLK": "PayBlock",
  "PYC": "PayCoin",
  "XPY": "PayCoin",
  "PFR": "PayFair",
  "PAYP": "PayPeer",
  "PPP": "PayPie",
  "PYP": "PayPro",
  "PYN": "Paycentos",
  "CON_": "Paycon",
  "PGC*": "Paygine",
  "PMNT": "Paymon",
  "PYT": "Payther",
  "PEC": "PeaceCoin",
  "PRLPAY": "PearlPay",
  "XPB": "Pebble Coin",
  "PBL": "Pebbles",
  "PCL": "Peculium",
  "PCO": "Pecunio",
  "PCN": "PeepCoin",
  "PMTN": "Peer Mountain",
  "PPC": "PeerCoin",
  "GUESS": "Peerguess",
  "PPY": "Peerplays",
  "PGC": "Pegascoin",
  "PEN*": "PenCoin",
  "PNT": "Penta",
  "PTA": "PentaCoin",
  "PNY": "Peony Coin",
  "MAN": "People",
  "MEME": "Pepe",
  "PEPECASH": "Pepe Cash",
  "XPR": "Permian",
  "PIE": "Persistent Information Exchange",
  "PERU": "PeruCoin",
  "PTC": "PesetaCoin",
  "PSB": "PesoBit",
  "PETL": "Petlife",
  "PTR": "Petro",
  "XPD": "PetroDollar",
  "PXL": "Phalanx",
  "SOUL*": "Phantasma",
  "PNX": "PhantomX",
  "XPH": "PharmaCoin",
  "PHS": "PhilosophersStone",
  "PXC": "PhoenixCoin",
  "PHM": "Phomeum",
  "PHR*": "Phore",
  "PHTC": "Photochain",
  "PHO": "Photon",
  "PHT": "Photon Token",
  "PHR": "Phreak",
  "PGN": "Pigeoncoin",
  "PIGGY": "Piggy Coin",
  "PKC": "Pikciochain",
  "PLR": "Pillar",
  "PTT": "Pink Taxi Token",
  "PINK": "PinkCoin",
  "PINMO": "Pinmo",
  "PCOIN": "Pioneer Coin",
  "PIO": "Pioneershares",
  "SKULL": "Pirate Blocks",
  "PIRATE": "PirateCash",
  "PIRL": "Pirl",
  "PIZZA": "PizzaCoin",
  "PLAI": "Plair",
  "PLAN": "Plancoin",
  "PLANET": "PlanetCoin",
  "PLNX": "Planumex",
  "XPT": "Plata",
  "PTNX": "Platin",
  "PLC*": "PlatinCoin",
  "PNC": "PlatiniumCoin",
  "XPTX": "PlatinumBAR",
  "LUC": "Play 2 Live",
  "PLA": "PlayChip",
  "PXG": "PlayGame",
  "PKT": "Playkey",
  "DN8": "Pldgr",
  "PLG": "Pledgecamp",
  "PLX": "PlexCoin",
  "PLURA": "PluraCoin",
  "PLC": "PlusCoin",
  "PLUS1": "PlusOneCoin",
  "GPPT": "Pluto Project Coin",
  "PTC**": "Plutocoin",
  "PLU": "Pluton",
  "PLTX": "PlutusX",
  "POE": "Po.et",
  "POS": "PoSToken",
  "POA": "Poa Network",
  "XPS": "PoisonIvyCoin",
  "XPOKE": "PokeChain",
  "POKER": "PokerCoin",
  "XPST": "PokerSports",
  "PAL": "PolicyPal Network",
  "POLIS": "PolisPay",
  "POLY": "PolyBit",
  "NCT": "PolySwarm",
  "PLBT": "Polybius",
  "POLY*": "Polymath Network",
  "PON": "Ponder",
  "PSK": "Pool of Stake",
  "XSP": "PoolStamp",
  "PPS": "PopulStay",
  "POP": "PopularCoin",
  "PPT": "Populous",
  "PEX": "PosEx",
  "PSD": "Poseidon",
  "PCCM": "Poseidon Chain",
  "OCEAN": "Poseidon Foundation",
  "POSQ": "Poseidon Quark",
  "POST": "PostCoin",
  "POT": "PotCoin",
  "POWR": "Power Ledger",
  "PSM": "Prasm",
  "PRE": "Premium",
  "ENTT": "Presale Ventures",
  "PRE*": "Presearch",
  "HILL": "President Clinton",
  "PRES": "President Trump",
  "PBT": "Primalbase",
  "PST": "Primas",
  "PSF": "Prime Shipping Foundation",
  "PXI": "Prime-X1",
  "PRIME": "PrimeChain",
  "XPM": "PrimeCoin",
  "PRX": "Printerium",
  "PRM": "PrismChain",
  "PIVX": "Private Instant Verified Transaction",
  "PRIX": "Privatix",
  "PZM": "Prizm",
  "PRA": "ProChain",
  "XPRO": "ProCoin",
  "PROC": "ProCurrency",
  "PCM": "Procom",
  "PROD*": "Productivist",
  "PHC": "Profit Hunters Coin",
  "PDC": "Project Decorum",
  "JTX": "Project J",
  "PAI*": "Project Pai",
  "OMX": "Project Shivom",
  "PRFT": "Proof Suite Token",
  "PROPS": "Props",
  "PTC*": "Propthereum",
  "PRO": "Propy",
  "VRP": "Prosense.tv",
  "PGL": "Prospectors",
  "PRC": "ProsperCoin",
  "PROTON": "Proton",
  "XES": "Proxeus",
  "XPX": "ProximaX",
  "PSEUD": "PseudoCash",
  "PSY": "Psilocybin",
  "PULSE": "Pulse",
  "PMA": "PumaPay",
  "NPXS": "Pundi X",
  "PUPA": "PupaCoin",
  "PURA": "Pura",
  "PURE": "Pure",
  "VIDZ": "PureVidz",
  "PGT": "Puregold token",
  "PURK": "Purk",
  "PRPL": "Purple Token",
  "PRPS": "Purpose",
  "HLP": "Purpose Coin",
  "PUSHI": "Pushi",
  "PUT": "PutinCoin",
  "PYLNT": "Pylon Network",
  "QLC": "QLC Chain",
  "QTUM": "QTUM",
  "QUSD": "QUSD",
  "QBT*": "Qbao",
  "QOBI": "Qobit",
  "QORA": "QoraCoin",
  "XQR": "Qredit",
  "QBK": "QuBuck Coin",
  "eQUAD": "Quadrant Protocol",
  "QNT": "Quant",
  "QNTU": "Quanta",
  "QUANT": "Quantler",
  "QNTR": "Quantor",
  "QSP": "Quantstamp",
  "QAU": "Quantum",
  "QRL": "Quantum Resistant Ledger",
  "Q1S": "Quantum1Net",
  "QKC": "QuarkChain",
  "QRK": "QuarkCoin",
  "QTZ": "Quartz",
  "QUA": "Quasa",
  "QTL": "Quatloo",
  "QCN": "Quazar Coin",
  "Q2C": "QubitCoin",
  "QBC": "Quebecoin",
  "QCX": "QuickX Protocol",
  "QSLV": "Quicksilver coin",
  "QUIZ": "Quizando",
  "QUN": "QunQun",
  "QASH": "Quoine Liquid",
  "XQN": "Quotient",
  "QVT": "Qvolta",
  "QWARK": "Qwark",
  "QWC": "Qwertycoin",
  "RFL": "RAFL",
  "RAIZER": "RAIZER",
  "KRX": "RAVN Korrax",
  "RAWG": "RAWG",
  "RAC": "RAcoin",
  "RHOC": "RChain",
  "RCN*": "RCoin",
  "REAL": "REAL",
  "REBL": "REBL",
  "MWAT": "RED MegaWatt",
  "RST": "REGA Risk Sharing Token",
  "REME": "REME-Coin",
  "REM": "REMME",
  "RENC": "RENC",
  "RGC": "RG Coin",
  "RIF": "RIF Token",
  "ROI": "ROIcoin",
  "ROS": "ROS Coin",
  "RFT": "RYFTS",
  "RADI": "RadicalCoin",
  "RADS": "Radium",
  "RDN": "RadonPay",
  "RDN*": "Raiden Network",
  "RF": "Raido Financial",
  "RAINC": "RainCheck",
  "RAP": "Rapture",
  "ROC": "Rasputin Online Coin",
  "RTE": "Rate3",
  "XRA": "Ratecoin",
  "RATIO": "Ratio",
  "RAVE": "Ravelous",
  "RVN": "Ravencoin",
  "RAYS": "Rays Network",
  "RZR": "RazorCoin",
  "RWE": "Real-World Evidence",
  "RCT": "RealChain",
  "REA": "Realisto",
  "RCC": "Reality Clash",
  "XRK": "RecordsKeeper",
  "RRT": "Recovery Right Tokens",
  "RRC": "Recycling Regeneration Chain",
  "PHX": "Red Pulse Phoenix",
  "REDC": "RedCab",
  "RCX": "RedCrowCoin",
  "RED": "Redcoin",
  "RDD": "Reddcoin",
  "REDN": "Reden",
  "REE": "ReeCoin",
  "REF": "RefToken",
  "RFR": "Refereum",
  "REC": "Regalcoin",
  "RDS": "Reger Diamond",
  "RLX": "Relex",
  "REL": "Reliance",
  "REMCO": "Remco",
  "RPM": "Render Payment",
  "RNDR": "Render Token",
  "RNS": "RenosCoin",
  "BERRY": "Rentberry",
  "REPO": "Repo Coin",
  "RPB": "Republia",
  "REN": "Republic Token",
  "REPUX": "Repux",
  "REQ": "Request Network",
  "RMS": "Resumeo Shares",
  "RGT": "Retail.Global",
  "RBIT": "ReturnBit",
  "RNC": "ReturnCoin",
  "R": "Revain",
  "REV": "Revenu",
  "RVR": "Revolution VR",
  "XRE": "RevolverCoin",
  "RWD": "Reward Vision",
  "RMOB": "RewardMob",
  "RHEA": "Rhea",
  "XRL": "Rialto.AI",
  "RBR": "Ribbit Rewards",
  "RICE": "RiceCoin",
  "RIDE": "Ride My Car",
  "RIC": "Riecoin",
  "RMESH": "RightMesh",
  "RBT": "Rimbit",
  "RING": "RingCoin",
  "RIPO": "RipOffCoin",
  "RIPAX": "RipaEx",
  "RCN": "Ripio",
  "RIPT": "RiptideCoin",
  "RBX": "RiptoBuX",
  "RISE": "Rise",
  "RVT": "Rivetz",
  "RAC**": "RoBET",
  "ROBET": "RoBet",
  "RBDT": "RoBust Defense Token",
  "PUT*": "Robin8 Profile Utility Token",
  "RAC*": "RoboAdvisorCoin",
  "ROX": "Robotina",
  "RKT": "Rock Token",
  "ROK": "Rockchain",
  "ROCK*": "RocketCoin",
  "RPC": "RonPaulCoin",
  "RSC": "Ronaldinho Soccer Coin",
  "ROOT": "RootCoin",
  "ROOTS": "RootProject",
  "RT2": "RotoCoin",
  "ROUND": "RoundCoin",
  "ROE": "Rover Coin",
  "RKC": "Royal Kingdom Coin",
  "RYC": "RoyalCoin",
  "ROYAL": "RoyalCoin",
  "RYCN": "RoyalCoin 2.0",
  "RBIES": "Rubies",
  "RUBY": "Rubius",
  "RUBIT": "Rublebit",
  "RBY": "RubyCoin",
  "RUFF": "Ruff",
  "RUPX": "Rupaya",
  "RUP": "Rupee",
  "RC": "Russiacoin",
  "RMC": "Russian Mining Coin",
  "RUST": "RustCoin",
  "RUSTBITS": "Rustbits",
  "RYO": "Ryo",
  "S8C": "S88 Coin",
  "SABR": "SABR Coin",
  "SGA": "SAGA",
  "SAR*": "SARCoin",
  "SLY": "SELFLLERY",
  "SGAT": "SGAT",
  "SGP": "SGPay",
  "XSH": "SHIELD",
  "SIDT": "SID Token",
  "SKYFT": "SKYFchain",
  "SMNX": "SMNX",
  "SSX": "SOMESING",
  "SNM": "SONM",
  "SXDT": "SPECTRE Dividend Token",
  "SXUT": "SPECTRE Utility Token",
  "SPICE": "SPiCE Venture Capital",
  "SRCOIN": "SRCoin",
  "SSV": "SSVCoin",
  "STAC": "STAC",
  "STACS": "STACS Token",
  "STRS": "STARS",
  "EURS": "STASIS EURS",
  "STEX": "STEX",
  "STK": "STK Token",
  "STS": "STRESScoin",
  "STRY": "STRYKZ",
  "SUQA": "SUQA",
  "SaTT": "SaTT",
  "HAVEN": "Safe Haven",
  "XSTC": "Safe Trade Coin",
  "SAFE": "SafeCoin",
  "SAFEX": "SafeExchangeCoin",
  "SFR": "SaffronCoin",
  "SAF": "Safinus",
  "SAGA": "SagaCoin",
  "SFU": "Saifu",
  "SKB*": "Sakura Bloom",
  "SKR": "Sakuracoin",
  "SAL": "SalPay",
  "SALT": "Salt Lending",
  "SLS": "SaluS",
  "SMSR": "Samsara Coin",
  "SND": "Sandcoin",
  "SAN": "Santiment",
  "SPN*": "Sapien Network",
  "XAI": "SapienceCoin",
  "XRF": "Sarf",
  "XRN": "Saronite",
  "SAT": "Satisfaction Token",
  "STV": "Sativa Coin",
  "MAD*": "SatoshiMadness",
  "SAT2": "Saturn2Coin",
  "STO": "Save The Ocean",
  "SANDG": "Save and Gain",
  "SWC": "Scanetchain Token",
  "ST": "Scienceroot",
  "SNcoin": "ScientificCoin",
  "SCOOBY": "Scooby coin",
  "SCORE": "Scorecoin",
  "SCOR": "Scorista",
  "SCR*": "Scorum",
  "SCOT": "Scotcoin",
  "SCRIBE": "Scribe Network",
  "SCRL": "Scroll",
  "DDD": "Scry.info",
  "SCRPT": "ScryptCoin",
  "SCT": "ScryptToken",
  "SRT": "Scrypto",
  "SEAL": "Seal Network",
  "SECI": "Seci",
  "SCRT": "SecretCoin",
  "SRC": "SecureCoin",
  "SEC": "SecureCryptoPayments",
  "SRXIO": "Securix",
  "SET": "Securosys",
  "SEEDS": "SeedShares",
  "SEELE": "Seele",
  "B2X": "SegWit2x",
  "SEL": "SelenCoin",
  "STOR": "Self Storage Coin",
  "KEY": "SelfKey",
  "SSC": "SelfSell",
  "SGO": "Selfie GO",
  "SEM": "Semux",
  "SDRN": "Senderon",
  "SNS": "Sense",
  "SENSE": "Sense Token",
  "SEN": "Sentaro",
  "EMOT": "Sentigraph.io",
  "SENT": "Sentinel",
  "SENC": "Sentinel Chain",
  "UPP": "Sentinel Protocol",
  "SNTVT": "Sentivate",
  "SEQ": "Sequence",
  "SERA": "Seraph",
  "SRNT": "Serenity",
  "SRV": "ServAdvisor",
  "SETH": "Sether",
  "SP": "Sex Pistols",
  "SXC": "SexCoin",
  "SHA": "Shacoin",
  "SHADE": "ShadeCoin",
  "SDC": "ShadowCash",
  "SHARD": "ShardCoin",
  "SS": "Sharder",
  "SSS": "ShareChain",
  "eSwitch": "ShareMeAll",
  "SHR": "ShareRing",
  "SAK": "SharkCoin",
  "SHKG": "SharkGate",
  "SHP*": "Sharpe Capital",
  "JEW": "Shekel",
  "SHLD": "ShieldCoin",
  "SHIFT": "Shift",
  "SH": "Shilling",
  "SHE": "Shine Chain",
  "SHIP": "ShipChain",
  "SHPT": "Shipit",
  "SHORTY": "ShortyCoin",
  "SHOW": "ShowCoin",
  "HAND": "ShowHand",
  "SHPING": "Shping Coin",
  "SHREK": "ShrekCoin",
  "SCH": "Sia Cash Coin",
  "SC": "Siacoin",
  "SIB": "SibCoin",
  "SGL": "Sigil",
  "SIG": "Signal",
  "SGN": "Signals Network",
  "SIGT": "Signatum",
  "SNTR": "Silent Notary",
  "SILKT": "SilkChain",
  "SILK": "SilkCoin",
  "OST": "Simple Token",
  "SPLB": "SimpleBank",
  "SIGU": "Singular",
  "SNGLS": "SingularDTV",
  "AGI": "SingularityNET",
  "SRN": "SirinLabs",
  "SKC": "Skeincoin",
  "SKI": "Skillchain",
  "SKIN": "Skincoin",
  "SKRP": "Skraps",
  "SKR*": "Skrilla Token",
  "SKM": "Skrumble Network",
  "SKB": "SkullBuzz",
  "SKYM": "SkyMap",
  "SKY": "Skycoin",
  "SLX": "Slate",
  "SLM": "SlimCoin",
  "SLING": "Sling Coin",
  "RBTC": "Smart Bitcoin",
  "SIFT": "Smart Investment Fund Token",
  "POD": "Smart League",
  "TASH": "Smart Trip Platform",
  "VALOR": "Smart Valor",
  "SMART*": "SmartBillions",
  "SMART": "SmartCash",
  "SMC": "SmartCoin",
  "SLST": "SmartLands",
  "SMT*": "SmartMesh",
  "SMLY": "SmileyCoin",
  "SMILO": "Smilo",
  "SMOKE": "Smoke",
  "SMF": "SmurfCoin",
  "SNPC": "SnapCoin",
  "SNIP": "SnipCoin",
  "SNOV": "Snovio",
  "XSG": "Snowgem",
  "ONG": "SoMee.Social",
  "SOAR": "Soarcoin",
  "SLT": "Social Lending Network",
  "SMAC": "Social Media Coin",
  "SMT": "Social Media Market",
  "SEND": "Social Send",
  "SOCC": "SocialCoin",
  "SG": "SocialGood",
  "SREUR": "SocialRemit",
  "XBOT": "SocialXbotCoin",
  "SCL": "Sociall",
  "SOIL": "SoilCoin",
  "SOJ": "Sojourn Coin",
  "SOL": "Sola",
  "SDAO": "Solar DAO",
  "SLR": "SolarCoin",
  "CELL": "SolarFarm",
  "SRX": "Solarex",
  "SFC": "Solarflarecoin",
  "XLR": "Solaris",
  "SOLE": "SoleCoin",
  "SOLID": "Solidified",
  "SCT*": "Soma",
  "SONG": "Song Coin",
  "SSD": "Sonic Screw Driver Coin",
  "SOON": "SoonCoin",
  "SPHTX": "SophiaTX",
  "SNK": "Sosnovkino",
  "SOUL": "SoulCoin",
  "SPX": "Sp8de",
  "SCASH": "SpaceCash",
  "SPC*": "SpaceChain",
  "SPACE": "SpaceCoin",
  "SPA": "SpainCoin",
  "SPANK": "SpankChain",
  "SPK": "SparksPay",
  "SPEC": "SpecCoin",
  "SPX*": "Specie",
  "XSPEC": "Spectre",
  "SPEND": "Spend",
  "SPHR": "Sphere Coin",
  "XID": "Sphre AIR",
  "SPIKE": "Spiking",
  "SPC": "SpinCoin",
  "SPD*": "Spindle",
  "SPKZ": "Spokkz",
  "SPORT": "SportsCoin",
  "SFT": "SportsFix",
  "SPF": "SportyCo",
  "SPOT": "Spotcoin",
  "SPT": "Spots",
  "SPOTS": "Spots",
  "SPR": "Spreadcoin",
  "SPRTZ": "SpritzCoin",
  "SPRTS": "Sprouts",
  "SQP": "SqPay",
  "SQL": "Squall Coin",
  "SQR": "Squeezer",
  "XSI": "Stability Shares",
  "SBC": "StableCoin",
  "USDS": "StableUSD",
  "DSLA": "Stacktical",
  "STCN": "Stakecoin",
  "XSN": "Stakenet",
  "STA*": "Stakers",
  "STHR": "Stakerush",
  "LABX": "Stakinglab",
  "STALIN": "StalinCoin",
  "STC": "StarChain",
  "STR*": "StarCoin",
  "STAR*": "StarCoin",
  "SRC*": "StarCredits",
  "KST": "StarKST",
  "STT": "Staramba",
  "STAR": "Starbase",
  "START": "StartCoin",
  "STA": "Starta",
  "STP": "StashPay",
  "SQOIN": "StasyQ",
  "SNT": "Status Network Token",
  "STAX": "Staxcoin",
  "XST": "StealthCoin",
  "PNK": "SteamPunk",
  "STEEM": "Steem",
  "SBD*": "Steem Backed Dollars",
  "XLM": "Stellar",
  "XTL": "Stellite",
  "SCIA": "Stem Cell",
  "STN": "Steneum Coin",
  "STEPS": "Steps",
  "SLG": "SterlingCoin",
  "SPD": "Stipend",
  "STIPS": "Stips",
  "STOCKBET": "StockBet",
  "SCC": "StockChain Coin",
  "STQ": "Storiqa Token",
  "STORJ": "Storj",
  "SJCX": "StorjCoin",
  "STORM": "Storm",
  "STX": "Stox",
  "STAK": "Straks",
  "SISA": "Strategic Investments in Significant Areas",
  "STRAT": "Stratis",
  "SSH": "StreamSpace",
  "STM": "Streamity",
  "DATA": "Streamr DATAcoin",
  "SHND": "StrongHands",
  "SUT": "Suapp",
  "SUB*": "Subscriptio",
  "SUB": "Substratum Network",
  "SUCR": "Sucre",
  "SGC": "Sudan Gold Coin",
  "SGR": "Sugar Exchange",
  "SUMO": "Sumokoin",
  "SNC": "SunContract",
  "SSTC": "SunShotCoin",
  "SUP": "Supcoin",
  "SBTC": "Super Bitcoin",
  "SUPER": "SuperCoin",
  "UNITY": "SuperNET",
  "SEED": "Superbloom",
  "M1": "SupplyShock",
  "SPM": "Supreme",
  "RMT": "SureRemit",
  "SUR": "Suretly",
  "SWA": "Swace",
  "SWACH": "Swachhcoin",
  "BUCKS": "SwagBucks",
  "SWT": "Swarm City Token",
  "SWM": "Swarm Fund",
  "SWARM": "SwarmCoin",
  "SWEET": "SweetStake",
  "SWFTC": "SwftCoin",
  "SWING": "SwingCoin",
  "SCN": "Swiscoin",
  "CHSB": "SwissBorg",
  "SRC**": "SwissRealCoin",
  "SIC": "Swisscoin",
  "SWTH": "Switcheo",
  "SDP": "SydPakCoin",
  "SYLO": "Sylo",
  "SYNC": "SyncCoin",
  "MFG": "SyncFab",
  "SYC": "SynchroCoin",
  "SYNCO": "Synco",
  "SYNX": "Syndicate",
  "AMP": "Synereo",
  "SNRG": "Synergy",
  "SNX": "Synthetix",
  "SYS": "SysCoin",
  "TBT": "T-BOT",
  "TCX": "T-Coin",
  "TZO": "TANZŌ",
  "BAR": "TBIS token",
  "TDFB": "TDFB",
  "TFD": "TE-FOOD",
  "TKY": "THEKEY Token",
  "TTN": "TITA Project",
  "TXM": "TMONEY",
  "TOA": "TOA Coin",
  "TPC": "TPCash",
  "TRX": "TRON",
  "XTROPTIONS": "TROPTIONS",
  "TTV": "TV-TWO",
  "TWISTR": "TWIST",
  "TTU": "TaTaTu",
  "TCHN": "Tachain",
  "TAG": "TagCoin",
  "TAJ": "TajCoin",
  "TAK": "TakCoin",
  "TKLN": "Taklimakan",
  "TALAO": "Talao",
  "TLNT": "Talent Token",
  "TCOIN": "Talenthon",
  "TAL": "Talentico",
  "TAM": "TamaGucci",
  "XTO": "Tao",
  "TTT": "Tap Project",
  "TAP": "TappingCoin",
  "TGT": "TargetCoin",
  "TAT": "Tatiana Coin",
  "TSE": "TattooCoin",
  "TEC": "TeCoin",
  "TCHB": "Teachers Blockchain",
  "TEAM": "TeamUP",
  "TECH": "TechCoin",
  "THS": "TechShares",
  "TEK": "TekCoin",
  "TEL": "Telcoin",
  "GRAM": "Telegram Open Network",
  "TELL": "Tellurion",
  "PAY": "TenX",
  "TENNET": "Tennet",
  "TENZ": "Tenzorum",
  "LED": "Terawatt",
  "TERN": "Ternio",
  "TRN": "Ternion",
  "TVA": "Terra Virtua",
  "TRC": "TerraCoin",
  "TECO": "TerraEcoCoin",
  "TGN": "TerraGreen",
  "TER": "TerraNovaCoin",
  "TESLA": "TeslaCoilCoin",
  "TES": "TeslaCoin",
  "USDT": "Tether",
  "TRA": "Tetra",
  "XTZ": "Tezos",
  "THNX": "ThankYou",
  "0xDIARY": "The 0xDiary Token",
  "ABYSS": "The Abyss",
  "EFX": "The EFFECT Network",
  "TFC": "The Freedom Coin",
  "GOVT": "The Government Network",
  "THC": "The Hempcoin",
  "SUNEX": "The Sun Exchange",
  "XVE": "The Vegan Initiative",
  "CHIEF": "TheChiefCoin",
  "GCC*": "TheGCCcoin",
  "VIG": "TheVig",
  "TCR": "Thecreed",
  "MAY": "Theresa May Coin",
  "THETA": "Theta",
  "TAGR": "Think And Get Rich Coin",
  "THRT": "ThriveToken",
  "TSC": "ThunderStake",
  "TIA": "Tianhe",
  "TBRS": "Tiberius",
  "TDX": "Tidex Token",
  "TNT": "Tierion",
  "TIE": "Ties Network",
  "TCH": "TigerCash",
  "TGC": "TigerCoin",
  "TIG": "Tigereum",
  "XTC": "TileCoin",
  "BILL": "TillBilly",
  "TIME": "Time",
  "TNB": "Time New Bank",
  "TME": "Timereum",
  "TMC": "TimesCoin",
  "TIMI": "Timicoin",
  "TIO*": "Tio Tour Guides",
  "TIP": "Tip Blockchain",
  "TTC": "TitCoin",
  "TITAN": "Titan",
  "TBAR": "Titanium BAR",
  "TIT": "TittieCoin",
  "TMT*": "ToTheMoon",
  "TODAY": "TodayCoin",
  "TKD": "Tokedo",
  "TAAS": "Token as a Service",
  "TKN": "TokenCard",
  "TCT": "TokenClub",
  "TDS": "TokenDesk",
  "TPAY*": "TokenPay",
  "ACE": "TokenStars",
  "TEAMT": "TokenStars TEAM Token",
  "AIRE": "Tokenaire",
  "TBX": "Tokenbox",
  "TEN": "Tokenomy",
  "TGTC": "Tokensgate",
  "TKS": "Tokes",
  "TKA": "Tokia",
  "TOK": "TokugawaCoin",
  "TOKC": "Tokyo Coin",
  "TOM": "Tomahawkcoin",
  "TBL": "Tombola",
  "TOMO": "TomoChain",
  "TOPC": "Topchain",
  "TOR": "TorCoin",
  "TOT": "TotCoin",
  "TRET": "Tourist Review",
  "BBC": "TraDove B2BCoin",
  "MTN": "TrackNetToken",
  "TRCT": "Tracto",
  "TXP": "Trade Pharma Network",
  "TIOX": "Trade Token X",
  "TIO": "Trade.io",
  "EXTP": "TradePlace",
  "TDZ": "Tradelize",
  "TRAID": "Traid",
  "TRAK": "TrakInvest",
  "TX": "Transfer",
  "TBCX": "TrashBurn",
  "AVALA": "Travala",
  "TRV": "Travel Coin",
  "TT": "TravelChain",
  "TLT": "Travelertoken",
  "TRF": "Travelflex",
  "TRAVEL": "Travelvee",
  "TMT**": "Traxia Membership Token",
  "TOT*": "Trecento Blockchain Capital",
  "TREX": "TreeBlock",
  "TZC": "TrezarCoin",
  "FORCE": "TriForce Tokens",
  "TRIA": "Triaconta",
  "TRI": "Triangles Coin",
  "TRIBE": "TribeToken",
  "TRICK": "TrickyCoin",
  "TRDT": "Trident",
  "GPS": "Triffic",
  "ID": "TrigID",
  "TRIG": "Trigger",
  "TIIM": "TriipMiles",
  "TNC": "Trinity Network Credit",
  "TRIO": "Tripio",
  "TRIP": "Trippki",
  "TRVC": "Trivecoin",
  "TRVR": "Trivver",
  "TRW": "Triwer",
  "TPG": "Troll Payment",
  "TKN*": "TrollTokens",
  "TROLL": "Trollcoin",
  "TRK": "TruckCoin",
  "TRCK": "Truckcoin",
  "TRUE": "True Chain",
  "TFL": "True Flip Lottery",
  "TUSD": "True USD",
  "TDP": "TrueDeck",
  "TGAME": "TrueGame",
  "TIC": "TrueInvestmentCoin",
  "TRUMP": "TrumpCoin",
  "TRST": "TrustCoin",
  "TRUST": "TrustPlus",
  "TTB": "TrustaBit",
  "FLEX": "TrustedCars FLEX",
  "WHO": "Truwho",
  "TYM": "Tryvium",
  "TLP": "TulipCoin",
  "TUR": "Turron",
  "TRTL": "TurtleCoin",
  "TUT": "Tutellus",
  "TRT": "TuurnT",
  "TWLV": "Twelve Coin",
  "TWC": "Twilight",
  "TWIST": "TwisterCoin",
  "UUU": "U Network",
  "UCASH": "U.CASH",
  "UCN": "UC Coin",
  "UCOINT": "UCOIN",
  "UCT": "UCOT",
  "UFO": "UFO Coin",
  "HVE": "UHIVE",
  "UMK": "UMKA",
  "UNX": "UNEOX",
  "XUP": "UPcoin",
  "UR": "UR",
  "URX": "URANIUMX",
  "USAT": "USAT",
  "USCOIN": "USCoin",
  "USDC": "USD Coin",
  "USDCT": "USDCT",
  "USOAMIC": "USOAMIC",
  "UBC": "Ubcoin",
  "UBEX": "Ubex",
  "UBQ": "Ubiq",
  "UBIQ": "Ubiqoin",
  "U": "Ucoin",
  "USC": "Ultimate Secure Cash",
  "UTC": "UltraCoin",
  "XUN": "UltraNote",
  "ULTC": "Umbrella",
  "UMC": "Umbrella Coin",
  "UNC": "UnCoin",
  "UNAT": "Unattanium",
  "NBOX": "Unboxed",
  "UNB": "UnbreakableCoin",
  "UNF": "Unfed Coin",
  "UBT": "UniBright",
  "CANDY": "UnicornGo Candy",
  "USX": "Unified Society USDEX",
  "UNIFY": "Unify",
  "UKG": "UnikoinGold",
  "UNIQ": "Uniqredit",
  "USDE": "UnitaryStatus Dollar",
  "UAEC": "United Arab Emirates Coin",
  "UEC": "United Emirates Coin",
  "UTT": "United Traders Token",
  "UBTC": "UnitedBitcoin",
  "GOALS": "UnitedFans",
  "UIS": "Unitus",
  "UTNP": "Universa",
  "UNIT": "Universal Currency",
  "UMO": "Universal Molecule",
  "URT": "Universal Recognition Token",
  "URP": "Universal Reward Protocol",
  "UNRC": "UniversalRoyalCoin",
  "UNI": "Universe",
  "UNO": "Unobtanium",
  "UP": "UpToken",
  "UFR": "Upfiring",
  "UQC": "Uquid Coin",
  "URALS": "Urals Coin",
  "URB": "Urbit Data",
  "URO": "UroCoin",
  "USE": "Usechain Token",
  "UETL": "Useless Eth Token Lite",
  "UET": "Useless Ethereum Token",
  "UTH": "Uther",
  "UTL": "Utile Network",
  "UTIL": "Utility Coin",
  "OOT": "Utrum",
  "UTK": "Utrust",
  "UWC": "Uwezocoin",
  "VIDT": "V-ID",
  "VANIG": "VANIG",
  "VANM": "VANM",
  "VAR": "VARcrypt",
  "VEGA": "VEGA",
  "VNTY": "VENOTY",
  "VRX Token": "VIARIUM",
  "VIBE": "VIBEHub",
  "VIP": "VIP Tokens",
  "VITE": "VITE",
  "VIVO": "VIVO Coin",
  "VLUX": "VLUX",
  "VTOS": "VTOS",
  "VTUUR": "VTUUR",
  "VTRD": "VTradeExchange",
  "VVI": "VV Coin",
  "VLD": "Valid",
  "VALID": "Validator Token",
  "VAL": "Valorbit",
  "VLR": "Valorem",
  "VANY": "Vanywhere",
  "VPRC": "VapersCoin",
  "VAPOR": "Vaporcoin",
  "VAD": "Varanida",
  "VLTC": "VaultCoin",
  "XVC": "Vcash",
  "VTHO": "VeChainThor",
  "VC": "Vecap",
  "VET": "Vechain",
  "VEC2": "VectorCoin 2.0",
  "VLX": "Velox",
  "VLT": "Veltor",
  "VENA": "Vena Network",
  "VNS": "Venus",
  "VENUS": "VenusEnergy",
  "VRA": "Verasity",
  "VNT": "Veredictum",
  "XVG": "Verge",
  "VRC": "VeriCoin",
  "VME": "VeriME",
  "VRF": "Verifier",
  "SPY": "Verifier",
  "CRED": "Verify",
  "VERI": "Veritaseum",
  "VRTY": "Verity",
  "VRM": "Verium",
  "VRN": "Vernam",
  "VRS": "Veros",
  "VERSA": "Versa Token",
  "VTC": "Vertcoin",
  "VTX": "Vertex",
  "VTEX": "Vertex",
  "VTL": "Vertical",
  "VEST": "VestChain",
  "VST": "Vestarin",
  "VEX": "Vexanium",
  "VZT": "Vezt",
  "VIA": "ViaCoin",
  "VIAZ": "Viaz",
  "VIB": "Viberate",
  "VIT": "Vice Industry Token",
  "VTM": "Victorieum",
  "VTY": "Victoriouscoin",
  "VIC": "Victorium",
  "VID": "VideoCoin",
  "VDO": "VidioCoin",
  "VIDI": "Vidion",
  "VIDY": "Vidy",
  "VIEW": "Viewly",
  "VEOT": "Viewo",
  "VIN": "VinChain",
  "VIOR": "ViorCoin",
  "IDORU": "Vip2Fan",
  "VIRAL": "Viral Coin",
  "VUC": "Virta Unique Coin",
  "VTA": "VirtaCoin",
  "XVP": "VirtacoinPlus",
  "VRT": "Virtual Reality Technology",
  "VRH": "Virtual Rehab",
  "VMC": "VirtualMining Coin",
  "VISIO": "Visio",
  "VNX": "VisionX",
  "VITAE": "Vitae",
  "VIU": "Viuly",
  "OGO": "VogoV",
  "VOISE": "Voise",
  "VOL": "VolAir",
  "VLTX": "Volentix",
  "VLP": "Volpo",
  "VTN": "Voltroon",
  "VOOT": "VootCoin",
  "VOT": "Votecoin",
  "VOYA": "Voyacoin",
  "VSX": "Vsync",
  "VTR": "Vtorrent",
  "VULC": "Vulcano",
  "W12": "W12 Protocol",
  "W3C": "W3Coin",
  "WAB": "WABnetwork",
  "WIN": "WCoin",
  "WETH": "WETH",
  "WRL": "WHIRL",
  "WMC": "WMCoin",
  "WOM": "WOM",
  "WOWX": "WOWX",
  "WRT": "WRTcoin",
  "WTXH": "WTX HUB",
  "WU": "WULET",
  "WABI": "WaBi",
  "WGR": "Wagerr",
  "WTC": "Waltonchain",
  "WAN": "Wanchain",
  "WAND": "WandX",
  "WRC*": "WarCoin",
  "WARP": "WarpCoin",
  "WASH": "WashingtonCoin",
  "WUG": "WatchUGot",
  "WMB": "WatermelonBlock",
  "WAVES": "Waves",
  "WCT": "Waves Community Token",
  "WGO": "WavesGO",
  "WNET": "Wavesnode.net",
  "WAY": "WayCoin",
  "WSX": "WeAreSatoshi",
  "WBY": "WeBuy",
  "WPR": "WePower",
  "WT": "WeToken",
  "WEALTH": "WealthCoin",
  "WVR": "Weave",
  "WEB*": "Webchain",
  "WEB": "Webcoin",
  "WDX": "WeiDex",
  "WELL": "Well",
  "WLME": "Wellmee",
  "WTL": "Welltrado",
  "WMK": "Wemark",
  "WEX": "Wexcoin",
  "WHL": "WhaleCoin",
  "AWT": "WhatsOnPic",
  "WHEN": "WhenHub",
  "WC": "WhiteCoin",
  "XWC": "WhiteCoin",
  "WIC": "Wi Coin",
  "WIIX": "Wiix",
  "WBB": "Wild Beast Coin",
  "WILD": "Wild Crypto",
  "WINS": "WinStars",
  "WHN": "Windhan Energy",
  "LIF": "Winding Tree",
  "WINE": "WineCoin",
  "WINGS": "Wings DAO",
  "WINK": "Wink",
  "WISC": "WisdomCoin",
  "WSC": "WiserCoin",
  "WSH": "Wish Finance",
  "WISH*": "WishFinance",
  "WIT": "Witcoin",
  "WLK": "Wolk",
  "WOMEN": "WomenCoin",
  "LOG": "Wood Coin",
  "WBBC": "World Bit Bank",
  "WCG": "World Crypto Gold",
  "WGC": "World Gold Coin",
  "XWT": "World Trade Funds",
  "WOBTC": "WorldBTC",
  "WDC": "WorldCoin",
  "WOP": "WorldPay",
  "WRC": "Worldcore",
  "WPT": "Worldopoly",
  "WAX": "Worldwide Asset eXchange",
  "WBTC": "Wrapped Bitcoin",
  "WYR": "Wyrify",
  "WYS": "Wysker",
  "XRED": "X Real Estate Development",
  "XCASH": "X-CASH",
  "XC": "X11 Coin",
  "X2": "X2Coin",
  "X8X": "X8Currency",
  "CHI": "XAYA",
  "XCZ": "XCOYNZ",
  "XCO": "XCoin",
  "XDE2": "XDE II",
  "XDNA": "XDNA",
  "XELS": "XELS Coin",
  "XTN": "XEND token",
  "XG": "XG Sports",
  "XMX": "XMax",
  "XOV": "XOVBank",
  "XRP": "XRP",
  "XBY": "XTRABYTES",
  "XUEZ": "XUEZ",
  "XXX": "XXXCoin",
  "XYO": "XY Oracle",
  "XNX": "XanaxCoin",
  "XAU": "XauCoin",
  "XAUR": "Xaurum",
  "XCSH": "Xcash",
  "XCEL": "XcelTrip",
  "XCG": "Xchange",
  "XNC": "XenCoin",
  "XEN": "XenixCoin",
  "XNN": "Xenon",
  "XNB": "Xeonbit",
  "MI": "XiaoMiCoin",
  "XDCE": "XinFin Coin",
  "XIOS": "Xios",
  "XT3": "Xt3ch",
  "XRBT": "Xtribe",
  "YAY": "YAYcoin",
  "YAC": "YAcCoin",
  "YACHTCO": "Yachtco",
  "YMC": "YamahaCoin",
  "YMZ": "Yamzu",
  "YBC": "YbCoin",
  "YDY": "Ydentity",
  "YEE": "Yee",
  "YBT": "YellowBetter",
  "YES": "YesCoin",
  "YOC": "YoCoin",
  "YOVI": "YobitVirtualCoin",
  "YON": "YondoCoin",
  "YSH": "Yoshi",
  "U42": "You42",
  "YOYOW": "Yoyow",
  "YUM": "Yumerium",
  "Z2": "Z2 Coin",
  "ZAB": "ZABERcoin",
  "ZAZA": "ZAZA",
  "ZT": "ZB Global",
  "ZCC": "ZCC Coin",
  "ZEC": "ZCash",
  "ZECD": "ZCashDarkCoin",
  "ZCG": "ZCashGOLD",
  "ZCL": "ZClassic",
  "XZC": "ZCoin",
  "ZEN": "ZEN",
  "ZEPH": "ZEPHYR",
  "ZINC": "ZINC",
  "ZIX": "ZIX Token",
  "ZLQ": "ZLiteQubit",
  "ZMN": "ZMINE",
  "ZNAQ": "ZNAQ",
  "ZPR": "ZPER",
  "ZSE": "ZSEcoin",
  "ZEX": "Zaddex",
  "ZAP": "Zap",
  "ZAT": "ZatGo",
  "ZYD": "ZayedCoin",
  "ZXT": "Zcrypt",
  "NZL": "Zealium",
  "ZCO": "Zebi Coin",
  "ZED": "ZedCoins",
  "ZPT": "Zeepin",
  "ZEEW": "Zeew",
  "ZEIT": "ZeitCoin",
  "ZEL": "Zelcash",
  "ZP": "Zen Protocol",
  "ZND": "Zenad",
  "ZENI": "Zennies",
  "ZNA": "Zenome",
  "ZER": "Zero",
  "ZCC1": "ZeroCarbon",
  "ZSC*": "ZeroState",
  "ZEST": "ZestCoin",
  "ZET2": "Zeta2Coin",
  "ZET": "ZetaCoin",
  "ZSC": "Zeusshield",
  "ZUC": "Zeux",
  "ZCN*": "Zichain",
  "ZBC": "Zilbercoin",
  "ZLA": "Zilla",
  "ZIL": "Zilliqa",
  "ZIP": "Zipper",
  "ZIPT": "Zippie",
  "ZOI": "Zoin",
  "ZNE": "ZoneCoin",
  "ZOOM": "ZoomCoin",
  "ZRC": "ZrCoin",
  "ZUP": "Zupply Token",
  "ZUR": "Zurcoin",
  "ZUUM": "Zuum",
  "AQU": "aQuest",
  "AXPR": "aXpire",
  "ELF": "aelf",
  "AXC": "autoXchange",
  "BPN": "beepnow",
  "OX": "betbox",
  "BITCNY": "bitCNY",
  "BITGOLD": "bitGold",
  "BITSILVER": "bitSilver",
  "BITUSD": "bitUSD",
  "CSQ": "cosquare",
  "DCS": "deCLOUDs",
  "DNT": "district0x",
  "ECHT": "e-Chat",
  "EBIT": "eBit",
  "EBTC": "eBitcoin",
  "EBST": "eBoost",
  "ELTC2": "eLTC",
  "LYQD": "eLYQD",
  "DEM": "eMark",
  "EMU": "eMusic",
  "ePRX": "eProxy",
  "EREAL": "eREAL",
  "EMPR": "empowr",
  "BLACK": "eosBLACK",
  "EOSDAC": "eosDAC",
  "XEP": "ephelants360",
  "FDX": "fidentiaX",
  "GCN": "gCn Coin",
  "FFUEL": "getFIFO",
  "HBE": "healthbank",
  "ICHN": "i-chain",
  "IBANK": "iBankCoin",
  "DEAL": "iDealCash",
  "ICE": "iDice",
  "IETH": "iEthereum",
  "RLC": "iEx.ec",
  "ILT": "iOlite",
  "ITU": "iTrue",
  "IW": "iWallet",
  "IXT": "iXledger",
  "IMU": "imusify",
  "ITM": "intimate.io",
  "MCN": "mCoin",
  "MVU": "meVu",
  "MIBO": "miBoodle",
  "MOOLYA": "moolyacoin",
  "NOS": "nOS",
  "redBUX": "redBUX",
  "SUSD": "sUSD",
  "SVD": "savedroid",
  "SBA": "simplyBrand",
  "UFT": "ufoodo",
  "UGC": "ugChain",
  "VSL": "vSlice",
  "VTAG": "veriTAG Token",
  "WBTC*": "wBTC",
  "OPET": "ÕpetFoundation"
}

const coinListArr = [];
for(let coinKey of Object.keys(coinListRaw)){
  coinListArr.push({
    "value": coinKey,
    "label": coinListRaw[coinKey]+" ("+coinKey+")"
  });
}
export const coinList = coinListArr;